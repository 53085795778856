*,
*::before,
*::after {
  box-sizing: border-box;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html {
  font-size: 100%;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
textarea {
  resize: vertical;
}

iframe {
  max-width: 100%;
}

fieldset {
  min-width: 0;
}

th {
  font-weight: inherit;
  text-align: inherit;
}

.show-for-sr {
  position: absolute;
  top: 0;
  visibility: hidden;
  size: 1px;
}

.no-js .show-for-js {
  display: none;
}

.js .hide-for-js {
  display: none;
}

.responsiveEmbed {
  position: relative;
  padding: 0 0 56.25%;
}
.responsiveEmbed iframe {
  position: absolute;
  top: 0;
  left: 0;
  size: 100% !important;
}

.sf-dump samp {
  white-space: pre;
}

.uk-notification-header {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: var(--notification-color);
  margin-bottom: 8px;
}

.uk-notification-text {
  display: block;
  font-size: 1rem;
  color: #2b2f2f;
  line-height: 1.4;
}

/* ========================================================================
   Component: Base
 ========================================================================== */
/*
 * 1. Set `font-size` to support `rem` units
 *    Not using `font` property because a leading hyphen (e.g. -apple-system) causes the font to break in IE11 and Edge
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 * 3. Style
 */
html {
  /* 1 */
  font-family: Poppins, sans-serif;
  font-size: 100%;
  font-weight: normal;
  line-height: 1.5;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  background: #fff;
  color: #666;
}

/*
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/* Links
 ========================================================================== */
/*
 * Remove the outline on focused links when they are also active or hovered
 */
a:active,
a:hover {
  outline: none;
}

/*
 * Style
 */
a,
.uk-link {
  color: #1e87f0;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.25s ease-in-out;
}

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link,
.uk-link-toggle:focus .uk-link {
  color: #0f6ecd;
  text-decoration: none;
}

/* Text-level semantics
 ========================================================================== */
/*
 * 1. Add the correct text decoration in Edge.
 * 2. The shorthand declaration `underline dotted` is not supported in Safari.
 */
abbr[title] {
  /* 1 */
  text-decoration: underline dotted;
  /* 2 */
  -webkit-text-decoration-style: dotted;
}

/*
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/*
 * 1. Consolas has a better baseline in running text compared to `Courier`
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Style
 */
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  /* 1 */
  font-family: Consolas, monaco, monospace;
  /* 2 */
  font-size: 0.875rem;
  /* 3 */
  color: #f0506e;
  white-space: nowrap;
}

/*
 * Emphasize
 */
em {
  color: inherit;
}

/*
 * Insert
 */
ins {
  background: #ffd;
  color: #666;
  text-decoration: none;
}

/*
 * Mark
 */
mark {
  background: #ffd;
  color: #666;
}

/*
 * Quote
 */
q {
  font-style: italic;
}

/*
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
 ========================================================================== */
/*
 * Remove the gap between embedded content and the bottom of their containers.
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * 1. Add responsiveness.
 * 2. Auto-scale the height. Only needed if `height` attribute is present.
 * 3. Corrects responsive `max-width` behavior if padding and border are used.
 * 4. Exclude SVGs for IE11 because they don't preserve their aspect ratio.
 */
canvas,
img,
video {
  /* 1 */
  max-width: 100%;
  /* 2 */
  height: auto;
  /* 3 */
  box-sizing: border-box;
}

/* 4 */
@supports (display: block) {
  svg {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
  }
}
/*
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/*
 * 1. Fix lazy loading images if parent element is set to `display: inline` and has `overflow: hidden`.
 * 2. Hide `alt` text for lazy loading images.
 * Note: Selector for background while loading img[data-src*='.jpg'][src*='data:image'] { background: grey; }
 */
img:not([src]) {
  /* 1 */
  min-width: 1px;
  /* 2 */
  visibility: hidden;
}

/*
 * Iframe
 * Remove border in all browsers
 */
iframe {
  border: 0;
}

/* Block elements
 ========================================================================== */
/*
 * Margins
 */
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 0 0;
}

/* Add margin if adjacent element */
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 0;
}

/* Headings
 ========================================================================== */
h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge {
  margin: 0 0 0 0;
  font-family: Poppins, sans-serif;
  font-weight: normal;
  color: #333;
  text-transform: none;
}

/* Add margin if adjacent element */
* + h1, * + .uk-h1,
* + h2, * + .uk-h2,
* + h3, * + .uk-h3,
* + h4, * + .uk-h4,
* + h5, * + .uk-h5,
* + h6, * + .uk-h6,
* + .uk-heading-small,
* + .uk-heading-medium,
* + .uk-heading-large,
* + .uk-heading-xlarge,
* + .uk-heading-2xlarge {
  margin-top: 0;
}

/*
 * Sizes
 */
h1, .uk-h1 {
  font-size: 2.23125rem;
  line-height: 1.5;
}

h2, .uk-h2 {
  font-size: 1.7rem;
  line-height: 1.5;
}

h3, .uk-h3 {
  font-size: 1.5rem;
  line-height: 1.5;
}

h4, .uk-h4 {
  font-size: 1.25rem;
  line-height: 1.5;
}

h5, .uk-h5 {
  font-size: 16px;
  line-height: 1.5;
}

h6, .uk-h6 {
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  h1, .uk-h1 {
    font-size: 2.625rem;
  }

  h2, .uk-h2 {
    font-size: 2rem;
  }
}
/* Lists
 ========================================================================== */
ul,
ol {
  padding-left: 0;
}

/*
 * Reset margin for nested lists
 */
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0;
}

/* Description lists
 ========================================================================== */
dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

/* Horizontal rules
 ========================================================================== */
/*
 * 1. Show the overflow in Chrome, Edge and IE.
 * 2. Add the correct text-align in Edge and IE.
 * 3. Style
 */
hr, .uk-hr {
  /* 1 */
  overflow: visible;
  /* 2 */
  text-align: inherit;
  /* 3 */
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid #e5e5e5;
}

/* Add margin if adjacent element */
* + hr,
* + .uk-hr {
  margin-top: 20px;
}

/* Address
 ========================================================================== */
address {
  font-style: normal;
}

/* Blockquotes
 ========================================================================== */
blockquote {
  margin: 0 0 20px 0;
  font-size: 1.25rem;
  line-height: 1.5;
  font-style: italic;
}

/* Add margin if adjacent element */
* + blockquote {
  margin-top: 20px;
}

/*
 * Content
 */
blockquote p:last-of-type {
  margin-bottom: 0;
}

blockquote footer {
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Preformatted text
 ========================================================================== */
/*
 * 1. Contain overflow in all browsers.
 */
pre {
  font: 0.875rem / 1.5 Consolas, monaco, monospace;
  color: #666;
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto;
}

pre code {
  font-family: Consolas, monaco, monospace;
}

/* Selection pseudo-element
 ========================================================================== */
::selection {
  background: #39f;
  color: #fff;
  text-shadow: none;
}

/* HTML5 elements
 ========================================================================== */
/*
 * 1. Add the correct display in Edge, IE 10+, and Firefox.
 * 2. Add the correct display in IE.
 */
details,
main {
  /* 2 */
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Pass media breakpoints to JS
 ========================================================================== */
/*
 * Breakpoints
 */
.uk-breakpoint-s::before {
  content: "640px";
}

.uk-breakpoint-m::before {
  content: "900px";
}

.uk-breakpoint-l::before {
  content: "1000px";
}

.uk-breakpoint-xl::before {
  content: "1200px";
}

:root {
  --uk-breakpoint-s: 640px;
  --uk-breakpoint-m: 900px;
  --uk-breakpoint-l: 1000px;
  --uk-breakpoint-xl: 1200px;
}

/* ========================================================================
   Component: Table
 ========================================================================== */
/*
 * 1. Remove most spacing between table cells.
 * 2. Behave like a block element
 * 3. Style
 */
.uk-table {
  /* 1 */
  border-collapse: collapse;
  border-spacing: 0;
  /* 2 */
  width: 100%;
  /* 3 */
  margin-bottom: 20px;
}

/* Add margin if adjacent element */
* + .uk-table {
  margin-top: 20px;
}

/* Header cell
 ========================================================================== */
/*
 * 1. Style
 */
.uk-table th {
  padding: 16px 12px;
  text-align: left;
  vertical-align: bottom;
  /* 1 */
  font-size: 16px;
  font-weight: bold;
  color: #666;
}

/* Cell
 ========================================================================== */
.uk-table td {
  padding: 16px 12px;
  vertical-align: top;
}

/*
 * Remove margin from the last-child
 */
.uk-table td > :last-child {
  margin-bottom: 0;
}

/* Footer
 ========================================================================== */
.uk-table tfoot {
  font-size: 0.875rem;
}

/* Caption
 ========================================================================== */
.uk-table caption {
  font-size: 0.875rem;
  text-align: left;
  color: #999;
}

/* Alignment modifier
 ========================================================================== */
.uk-table-middle,
.uk-table-middle td {
  vertical-align: middle !important;
}

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-table-divider > tr:not(:first-child),
.uk-table-divider > :not(:first-child) > tr,
.uk-table-divider > :first-child > tr:not(:first-child) {
  border-top: 1px solid #e5e5e5;
}

/*
 * Striped
 */
.uk-table-striped > tr:nth-of-type(odd),
.uk-table-striped tbody tr:nth-of-type(odd) {
  background: #f8f8f8;
}

/*
 * Hover
 */
.uk-table-hover > tr:hover,
.uk-table-hover tbody tr:hover {
  background: #ffd;
}

/* Active state
 ========================================================================== */
.uk-table > tr.uk-active,
.uk-table tbody tr.uk-active {
  background: #ffd;
}

/* Size modifier
 ========================================================================== */
.uk-table-small th,
.uk-table-small td {
  padding: 10px 12px;
}

.uk-table-large th,
.uk-table-large td {
  padding: 22px 12px;
}

/* Justify modifier
 ========================================================================== */
.uk-table-justify th:first-child,
.uk-table-justify td:first-child {
  padding-left: 0;
}

.uk-table-justify th:last-child,
.uk-table-justify td:last-child {
  padding-right: 0;
}

/* Cell size modifier
 ========================================================================== */
.uk-table-shrink {
  width: 1px;
}

.uk-table-expand {
  min-width: 150px;
}

/* Cell link modifier
 ========================================================================== */
/*
 * Does not work with `uk-table-justify` at the moment
 */
.uk-table-link {
  padding: 0 !important;
}

.uk-table-link > a {
  display: block;
  padding: 16px 12px;
}

.uk-table-small .uk-table-link > a {
  padding: 10px 12px;
}

/* Responsive table
 ========================================================================== */
/* Phone landscape and smaller */
@media (max-width: 899px) {
  .uk-table-responsive,
.uk-table-responsive tbody,
.uk-table-responsive th,
.uk-table-responsive td,
.uk-table-responsive tr {
    display: block;
  }

  .uk-table-responsive thead {
    display: none;
  }

  .uk-table-responsive th,
.uk-table-responsive td {
    width: auto !important;
    max-width: none !important;
    min-width: 0 !important;
    overflow: visible !important;
    white-space: normal !important;
  }

  .uk-table-responsive th:not(:first-child):not(.uk-table-link),
.uk-table-responsive td:not(:first-child):not(.uk-table-link),
.uk-table-responsive .uk-table-link:not(:first-child) > a {
    padding-top: 5px !important;
  }

  .uk-table-responsive th:not(:last-child):not(.uk-table-link),
.uk-table-responsive td:not(:last-child):not(.uk-table-link),
.uk-table-responsive .uk-table-link:not(:last-child) > a {
    padding-bottom: 5px !important;
  }

  .uk-table-justify.uk-table-responsive th,
.uk-table-justify.uk-table-responsive td {
    padding-left: 0;
    padding-right: 0;
  }
}
/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0;
}

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer;
}

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: currentcolor;
}

.uk-icon:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: currentcolor;
}

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0);
}

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
}

/* Style modifiers
 ========================================================================== */
/*
 * Link
 */
.uk-icon-link {
  color: #999;
}

.uk-icon-link:hover,
.uk-icon-link:focus {
  color: #666;
  outline: none;
}

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: #595959;
}

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Hover + Focus */
.uk-icon-button:hover,
.uk-icon-button:focus {
  background-color: #ebebeb;
  color: #666;
  outline: none;
}

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: #dfdfdf;
  color: #666;
}

/* ========================================================================
   Component: Form
 ========================================================================== */
/*
 * 1. Define consistent box sizing.
 *    Default is `content-box` with following exceptions set to `border-box`
 *    `select`, `input[type="checkbox"]` and `input[type="radio"]`
 *    `input[type="search"]` in Chrome, Safari and Opera
 *    `input[type="color"]` in Firefox
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers.
 */
.uk-input, .ValueSlider__field, .SiteForm__input,
.uk-select,
.SiteForm__select,
.uk-textarea,
.SiteForm__textarea,
.uk-radio,
.uk-checkbox,
.Checkbox__control {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
}

/*
 * Show the overflow in Edge.
 */
.uk-input, .ValueSlider__field, .SiteForm__input {
  overflow: visible;
}

/*
 * Remove the inheritance of text transform in Firefox.
 */
.uk-select, .SiteForm__select {
  text-transform: none;
}

/*
 * 1. Change font properties to `inherit` in all browsers
 * 2. Don't inherit the `font-weight` and use `bold` instead.
 * NOTE: Both declarations don't work in Chrome, Safari and Opera.
 */
.uk-select optgroup, .SiteForm__select optgroup {
  /* 1 */
  font: inherit;
  /* 2 */
  font-weight: bold;
}

/*
 * Remove the default vertical scrollbar in IE 10+.
 */
.uk-textarea, .SiteForm__textarea {
  overflow: auto;
}

/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-input[type=search]::-webkit-search-cancel-button, [type=search].ValueSlider__field::-webkit-search-cancel-button, [type=search].SiteForm__input::-webkit-search-cancel-button,
.uk-input[type=search]::-webkit-search-decoration,
[type=search].ValueSlider__field::-webkit-search-decoration,
[type=search].SiteForm__input::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
.uk-input[type=number]::-webkit-inner-spin-button, [type=number].ValueSlider__field::-webkit-inner-spin-button, [type=number].SiteForm__input::-webkit-inner-spin-button,
.uk-input[type=number]::-webkit-outer-spin-button,
[type=number].ValueSlider__field::-webkit-outer-spin-button,
[type=number].SiteForm__input::-webkit-outer-spin-button {
  height: auto;
}

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-input::-moz-placeholder, .ValueSlider__field::-moz-placeholder, .SiteForm__input::-moz-placeholder,
.uk-textarea::-moz-placeholder,
.SiteForm__textarea::-moz-placeholder {
  opacity: 1;
}

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-radio:not(:disabled),
.uk-checkbox:not(:disabled),
.Checkbox__control:not(:disabled) {
  cursor: pointer;
}

/*
 * Define consistent border, margin, and padding.
 */
.uk-fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

/* Input, select and textarea
 * Allowed: `text`, `password`, `datetime`, `datetime-local`, `date`,  `month`,
            `time`, `week`, `number`, `email`, `url`, `search`, `tel`, `color`
 * Disallowed: `range`, `radio`, `checkbox`, `file`, `submit`, `reset` and `image`
 ========================================================================== */
/*
 * Remove default style in iOS.
 */
.uk-input, .ValueSlider__field, .SiteForm__input,
.uk-textarea,
.SiteForm__textarea {
  -webkit-appearance: none;
}

/*
 * 1. Prevent content overflow if a fixed width is used
 * 2. Take the full width
 * 3. Reset default
 * 4. Style
 */
.uk-input, .ValueSlider__field, .SiteForm__input,
.uk-select,
.SiteForm__select,
.uk-textarea,
.SiteForm__textarea {
  /* 1 */
  max-width: 100%;
  /* 2 */
  width: 100%;
  /* 3 */
  border: 0 none;
  /* 4 */
  padding: 0 14px;
  background: var(--form-default-background);
  color: var(--form-default-color);
  border: 2px solid var(--form-default-border);
  padding-top: 14px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  transition-property: border-color, background-color;
  transition: 0.25s ease-in-out;
}
.uk-input:hover, .ValueSlider__field:hover, .SiteForm__input:hover,
.uk-select:hover,
.SiteForm__select:hover,
.uk-textarea:hover,
.SiteForm__textarea:hover {
  color: var(--form-hover-color);
  background-color: var(--form-hover-background);
  border-color: var(--form-hover-border);
}

/*
 * Single-line
 * 1. Allow any element to look like an `input` or `select` element
 * 2. Make sure line-height is not larger than height
 *    Also needed to center the text vertically
 */
.uk-input, .ValueSlider__field, .SiteForm__input,
.uk-select:not([multiple]):not([size]),
.SiteForm__select:not([multiple]):not([size]) {
  height: 58px;
  vertical-align: middle;
  /* 1 */
  display: inline-block;
}

/* 2 */
.uk-input:not(input), .ValueSlider__field:not(input), .SiteForm__input:not(input),
.uk-select:not(select),
.SiteForm__select:not(select) {
  line-height: 1.5;
}

/*
 * Multi-line
 */
.uk-select[multiple], [multiple].SiteForm__select,
.uk-select[size],
[size].SiteForm__select,
.uk-textarea,
.SiteForm__textarea {
  padding-top: 4px;
  padding-bottom: 4px;
  vertical-align: top;
  padding-top: 25px;
  min-height: 58px;
}

/* Focus */
.uk-input:focus, .ValueSlider__field:focus, .SiteForm__input:focus,
.uk-select:focus,
.SiteForm__select:focus,
.uk-textarea:focus,
.SiteForm__textarea:focus {
  outline: none;
  background-color: var(--form-active-background);
  color: var(--form-active-color);
  border-color: var(--form-active-border);
}

/* Disabled */
.uk-input:disabled, .ValueSlider__field:disabled, .SiteForm__input:disabled,
.uk-select:disabled,
.SiteForm__select:disabled,
.uk-textarea:disabled,
.SiteForm__textarea:disabled {
  background-color: var(--form-disabled-background);
  color: var(--form-disabled-color);
  border-color: var(--form-disabled-border);
}

/*
 * Placeholder
 */
.uk-input::-ms-input-placeholder, .ValueSlider__field::-ms-input-placeholder, .SiteForm__input::-ms-input-placeholder {
  color: transparent !important;
}

.uk-input::placeholder, .ValueSlider__field::placeholder, .SiteForm__input::placeholder {
  color: transparent;
}

.uk-textarea::-ms-input-placeholder, .SiteForm__textarea::-ms-input-placeholder {
  color: transparent !important;
}

.uk-textarea::placeholder, .SiteForm__textarea::placeholder {
  color: transparent;
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Small
 */
.uk-form-small {
  font-size: 0.875rem;
}

.uk-form-small:not(textarea):not([multiple]):not([size]) {
  height: 30px;
  padding-left: 8px;
  padding-right: 8px;
}

.uk-form-small:not(select):not(input):not(textarea) {
  line-height: 30px;
}

/*
 * Large
 */
.uk-form-large {
  font-size: 1.25rem;
}

.uk-form-large:not(textarea):not([multiple]):not([size]) {
  height: 55px;
  padding-left: 12px;
  padding-right: 12px;
}

.uk-form-large:not(select):not(input):not(textarea) {
  line-height: 55px;
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Error
 */
.uk-form-danger,
.uk-form-danger:focus {
  color: #f0506e;
}

/*
 * Success
 */
.uk-form-success,
.uk-form-success:focus {
  color: #32d296;
}

/*
 * Blank
 */
.uk-form-blank {
  background: none;
}

/* Width modifiers (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Fixed widths
 * Different widths for mini sized `input` and `select` elements
 */
input.uk-form-width-xsmall {
  width: 50px;
}

select.uk-form-width-xsmall {
  width: 75px;
}

.uk-form-width-small {
  width: 130px;
}

.uk-form-width-medium {
  width: 200px;
}

.uk-form-width-large {
  width: 500px;
}

/* Select
 ========================================================================== */
/*
 * 1. Remove default style. Also works in Firefox
 * 2. Style
 * 3. Remove default style in IE 10/11
 * 4. Set `color` for options in the select dropdown, because the inherited `color` might be too light.
 */
.uk-select:not([multiple]):not([size]), .SiteForm__select:not([multiple]):not([size]) {
  /* 1 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 2 */
  padding-right: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

/* 3 */
.uk-select:not([multiple]):not([size])::-ms-expand, .SiteForm__select:not([multiple]):not([size])::-ms-expand {
  display: none;
}

/* 4 */
.uk-select:not([multiple]):not([size]) option, .SiteForm__select:not([multiple]):not([size]) option {
  color: #444;
}

/*
 * Disabled
 */
.uk-select:not([multiple]):not([size]):disabled, .SiteForm__select:not([multiple]):not([size]):disabled {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

/* Datalist
 ========================================================================== */
/*
 * 1. Remove default style in Chrome
 */
.uk-input[list], [list].ValueSlider__field, [list].SiteForm__input {
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

.uk-input[list]:hover, [list].ValueSlider__field:hover, [list].SiteForm__input:hover,
.uk-input[list]:focus,
[list].ValueSlider__field:focus,
[list].SiteForm__input:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

/* 1 */
.uk-input[list]::-webkit-calendar-picker-indicator, [list].ValueSlider__field::-webkit-calendar-picker-indicator, [list].SiteForm__input::-webkit-calendar-picker-indicator {
  display: none;
}

/* Radio and checkbox
 * Note: Does not work in IE11
 ========================================================================== */
/*
 * 1. Style
 * 2. Make box more robust so it clips the child element
 * 3. Vertical alignment
 * 4. Remove default style
 * 5. Fix black background on iOS
 * 6. Center icons
 */
.uk-radio,
.uk-checkbox,
.Checkbox__control {
  /* 1 */
  display: inline-block;
  height: 16px;
  width: 16px;
  /* 2 */
  overflow: hidden;
  /* 3 */
  margin-top: 0;
  vertical-align: middle;
  /* 4 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 5 */
  background-color: rgba(0, 156, 91, 0.4);
  /* 6 */
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.uk-radio {
  border-radius: 50%;
}

/* Focus */
.uk-radio:focus,
.uk-checkbox:focus,
.Checkbox__control:focus {
  outline: none;
}

/*
 * Checked
 */
.uk-radio:checked,
.uk-checkbox:checked,
.Checkbox__control:checked,
.uk-checkbox:indeterminate,
.Checkbox__control:indeterminate {
  background-color: rgba(0, 156, 91, 0.4);
}

/* Focus */
.uk-radio:checked:focus,
.uk-checkbox:checked:focus,
.Checkbox__control:checked:focus,
.uk-checkbox:indeterminate:focus,
.Checkbox__control:indeterminate:focus {
  background-color: rgba(0, 156, 91, 0.4);
}

/*
 * Icons
 */
.uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:checked, .Checkbox__control:checked {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.8132 7.12295C3.6222 7.12295 3.4292 7.04995 3.2832 6.90295L0.909203 4.52995C0.616203 4.23695 0.616203 3.76295 0.909203 3.46995C1.2022 3.17695 1.6762 3.17695 1.9692 3.46995L3.8132 5.31195L8.0292 1.09695C8.3222 0.803947 8.7962 0.803947 9.0892 1.09695C9.3822 1.38995 9.3822 1.86395 9.0892 2.15695L4.3432 6.90295C4.1972 7.04995 4.0052 7.12295 3.8132 7.12295Z' fill='%23009C5B'/%3E%3C/svg%3E");
}

.uk-checkbox:indeterminate, .Checkbox__control:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23fff%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/*
 * Disabled
 */
.uk-radio:disabled,
.uk-checkbox:disabled,
.Checkbox__control:disabled {
  background-color: #f8f8f8;
}

.uk-radio:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23999%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:disabled:checked, .Checkbox__control:disabled:checked {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.8132 7.12295C3.6222 7.12295 3.4292 7.04995 3.2832 6.90295L0.909203 4.52995C0.616203 4.23695 0.616203 3.76295 0.909203 3.46995C1.2022 3.17695 1.6762 3.17695 1.9692 3.46995L3.8132 5.31195L8.0292 1.09695C8.3222 0.803947 8.7962 0.803947 9.0892 1.09695C9.3822 1.38995 9.3822 1.86395 9.0892 2.15695L4.3432 6.90295C4.1972 7.04995 4.0052 7.12295 3.8132 7.12295Z' fill='%23009C5B'/%3E%3C/svg%3E");
}

.uk-checkbox:disabled:indeterminate, .Checkbox__control:disabled:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23999%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/* Legend
 ========================================================================== */
/*
 * Legend
 * 1. Behave like block element
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove padding so people aren't caught out if they zero out fieldsets.
 * 4. Style
 */
.uk-legend {
  /* 1 */
  width: 100%;
  /* 2 */
  color: inherit;
  /* 3 */
  padding: 0;
  /* 4 */
  font-size: 1.5rem;
  line-height: 1.4;
}

/* Custom controls
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 */
.uk-form-custom {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
}

/*
 * 1. Position and resize the form control to always cover its container
 * 2. Required for Firefox for positioning to the left
 * 3. Required for Webkit to make `height` work
 * 4. Hide controle and show cursor
 * 5. Needed for the cursor
 * 6. Clip height caused by 5. Needed for Webkit only
 */
.uk-form-custom select,
.uk-form-custom input[type=file] {
  /* 1 */
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* 2 */
  left: 0;
  /* 3 */
  -webkit-appearance: none;
  /* 4 */
  opacity: 0;
  cursor: pointer;
}

.uk-form-custom input[type=file] {
  /* 5 */
  font-size: 500px;
  /* 6 */
  overflow: hidden;
}

/* Label
 ========================================================================== */
/* Layout
 ========================================================================== */
/*
 * Stacked
 */
.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 10px;
}

/*
 * Horizontal
 */
/* Tablet portrait and smaller */
@media (max-width: 899px) {
  /* Behave like `uk-form-stacked` */
  .uk-form-horizontal .uk-form-label {
    display: block;
    margin-bottom: 10px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-form-horizontal .uk-form-label {
    width: 200px;
    margin-top: 7px;
    float: left;
  }

  .uk-form-horizontal .uk-form-controls {
    margin-left: 215px;
  }

  /* Better vertical alignment if controls are checkboxes and radio buttons with text */
  .uk-form-horizontal .uk-form-controls-text {
    padding-top: 7px;
  }
}
/* Icons
 ========================================================================== */
/*
 * 1. Set position
 * 2. Set width
 * 3. Center icon vertically and horizontally
 * 4. Style
 */
.uk-form-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 58px;
  /* 3 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 4 */
  color: #999;
}

/*
 * Required for `a`.
 */
.uk-form-icon:hover {
  color: #666;
}

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-form-icon:not(a):not(button):not(input) {
  pointer-events: none;
}

/*
 * Input padding
 */
.uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input, .uk-form-icon:not(.uk-form-icon-flip) ~ .ValueSlider__field, .uk-form-icon:not(.uk-form-icon-flip) ~ .SiteForm__input {
  padding-left: 58px !important;
}

/*
 * Position modifier
 */
.uk-form-icon-flip {
  right: 0;
  left: auto;
}

.uk-form-icon-flip ~ .uk-input, .uk-form-icon-flip ~ .ValueSlider__field, .uk-form-icon-flip ~ .SiteForm__input {
  padding-right: 58px !important;
}

/* ========================================================================
   Component: Container
 ========================================================================== */
/*
 * 1. Box sizing has to be `content-box` so the max-width is always the same and
 *    unaffected by the padding on different breakpoints. It's important for the size modifiers.
 */
.uk-container, .SiteFooter__container, .SiteHeader__container, .MobileBurgerContainer, .Layout__container {
  /* 1 */
  box-sizing: content-box;
  max-width: 1152px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container, .SiteFooter__container, .SiteHeader__container, .MobileBurgerContainer, .Layout__container {
    padding-left: 32px;
    padding-right: 32px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-container, .SiteFooter__container, .SiteHeader__container, .MobileBurgerContainer, .Layout__container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/*
 * Micro clearfix
 */
.uk-container::before, .SiteFooter__container::before, .SiteHeader__container::before, .MobileBurgerContainer::before, .Layout__container::before,
.uk-container::after,
.SiteFooter__container::after,
.SiteHeader__container::after,
.MobileBurgerContainer::after,
.Layout__container::after {
  content: "";
  display: table;
}

.uk-container::after, .SiteFooter__container::after, .SiteHeader__container::after, .MobileBurgerContainer::after, .Layout__container::after {
  clear: both;
}

/*
 * Remove margin from the last-child
 */
.uk-container > :last-child, .SiteFooter__container > :last-child, .SiteHeader__container > :last-child, .MobileBurgerContainer > :last-child, .Layout__container > :last-child {
  margin-bottom: 0;
}

/*
 * Remove padding from nested containers
 */
.uk-container .uk-container, .SiteFooter__container .uk-container, .uk-container .SiteFooter__container, .SiteFooter__container .SiteFooter__container, .SiteHeader__container .uk-container, .SiteHeader__container .SiteFooter__container, .uk-container .SiteHeader__container, .SiteFooter__container .SiteHeader__container, .SiteHeader__container .SiteHeader__container, .MobileBurgerContainer .uk-container, .MobileBurgerContainer .SiteFooter__container, .MobileBurgerContainer .SiteHeader__container, .uk-container .MobileBurgerContainer, .SiteFooter__container .MobileBurgerContainer, .SiteHeader__container .MobileBurgerContainer, .MobileBurgerContainer .MobileBurgerContainer, .Layout__container .uk-container, .Layout__container .SiteFooter__container, .Layout__container .SiteHeader__container, .Layout__container .MobileBurgerContainer, .uk-container .Layout__container, .SiteFooter__container .Layout__container, .SiteHeader__container .Layout__container, .MobileBurgerContainer .Layout__container, .Layout__container .Layout__container {
  padding-left: 0;
  padding-right: 0;
}

/* Size modifier
 ========================================================================== */
.uk-container-xsmall {
  max-width: 750px;
}

.uk-container-small, .Layout__container--small {
  max-width: 832px;
}

.uk-container-large, .SiteFooter__container, .SiteHeader__container, .Layout__container--large {
  max-width: 1248px;
}

.uk-container-xlarge {
  max-width: 1600px;
}

.uk-container-expand {
  max-width: none;
}

/* Expand modifier
 ========================================================================== */
/*
 * Expand one side only
 */
.uk-container-expand-left {
  margin-left: 0;
}

.uk-container-expand-right {
  margin-right: 0;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-expand-left.uk-container-xsmall,
.uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 32px);
  }

  .uk-container-expand-left.uk-container-small, .uk-container-expand-left.Layout__container--small,
.uk-container-expand-right.uk-container-small,
.uk-container-expand-right.Layout__container--small {
    max-width: calc(50% + (832px / 2) - 32px);
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-container-expand-left,
.uk-container-expand-right {
    max-width: calc(50% + (1152px / 2) - 40px);
  }

  .uk-container-expand-left.uk-container-xsmall,
.uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 40px);
  }

  .uk-container-expand-left.uk-container-small, .uk-container-expand-left.Layout__container--small,
.uk-container-expand-right.uk-container-small,
.uk-container-expand-right.Layout__container--small {
    max-width: calc(50% + (832px / 2) - 40px);
  }

  .uk-container-expand-left.uk-container-large, .uk-container-expand-left.SiteFooter__container, .uk-container-expand-left.SiteHeader__container, .uk-container-expand-left.Layout__container--large,
.uk-container-expand-right.uk-container-large,
.uk-container-expand-right.SiteFooter__container,
.uk-container-expand-right.SiteHeader__container,
.uk-container-expand-right.Layout__container--large {
    max-width: calc(50% + (1248px / 2) - 40px);
  }

  .uk-container-expand-left.uk-container-xlarge,
.uk-container-expand-right.uk-container-xlarge {
    max-width: calc(50% + (1600px / 2) - 40px);
  }
}
/* Item
 ========================================================================== */
/*
 * Utility classes to reset container padding on the left or right side
 * Note: It has to be negative margin on the item, because it's specific to the item.
 */
.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
  width: calc(100% + 16px);
}

.uk-container-item-padding-remove-left {
  margin-left: -16px;
}

.uk-container-item-padding-remove-right {
  margin-right: -16px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: calc(100% + 32px);
  }

  .uk-container-item-padding-remove-left {
    margin-left: -32px;
  }

  .uk-container-item-padding-remove-right {
    margin-right: -32px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: calc(100% + 40px);
  }

  .uk-container-item-padding-remove-left {
    margin-left: -40px;
  }

  .uk-container-item-padding-remove-right {
    margin-right: -40px;
  }
}
/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #999;
}

/* Hover + Focus */
.uk-close:hover,
.uk-close:focus {
  color: #666;
  outline: none;
}

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: rgba(255, 255, 255, 0.6);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
  backdrop-filter: blur(5px);
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-modal {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1;
}

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden;
}

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. Fix `max-width: 100%` not working in combination with flex and responsive images in IE11
 *    `!important` needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 448px;
  /* 3 */
  max-width: calc(100% - 0.01px) !important;
  /* 4 */
  background: #edeff2;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform;
  border-radius: 10px;
}

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0);
}

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px;
}

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none;
}

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0);
}

/* Sections
 ========================================================================== */
.uk-modal-body {
  padding: 48px 48px;
}
@media (max-width: 424px) {
  .uk-modal-body {
    padding: 40px 24px;
  }
}

.uk-modal-header {
  padding: 15px 30px;
  background: #f8f8f8;
}

.uk-modal-footer {
  padding: 15px 30px;
  background: #f8f8f8;
}

/*
 * Micro clearfix
 */
.uk-modal-body::before,
.uk-modal-body::after,
.uk-modal-header::before,
.uk-modal-header::after,
.uk-modal-footer::before,
.uk-modal-footer::after {
  content: "";
  display: table;
}

.uk-modal-body::after,
.uk-modal-header::after,
.uk-modal-footer::after {
  clear: both;
}

/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0;
}

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 2rem;
  line-height: 1.3;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*=uk-modal-close-] {
  position: absolute;
  z-index: 1010;
  top: 24px;
  right: 24px;
  padding: 5px;
}
html:root [class*=uk-modal-close-] {
  position: absolute;
}

/*
 * Remove margin from adjacent element
 */
[class*=uk-modal-close-]:first-child + * {
  margin-top: 0;
}

/*
 * Hover
 */
/*
 * Default
 */
/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white;
}

.uk-modal-close-outside:hover {
  color: #fff;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%);
  }
}
/*
 * Full
 */
/* ========================================================================
   Component: Lightbox
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 * 7. Prevent cancellation of pointer events while dragging
 */
.uk-lightbox {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 5 */
  background: #000;
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
  /* 7 */
  touch-action: pinch-zoom;
}

/*
 * Open
 * 1. Center child
 * 2. Fade-in
 */
.uk-lightbox.uk-open {
  display: block;
  /* 2 */
  opacity: 1;
}

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-lightbox-page {
  overflow: hidden;
}

/* Item
 ========================================================================== */
/*
 * 1. Center child within the viewport
 * 2. Not visible by default
 * 3. Color needed for spinner icon
 * 4. Optimize animation
 * 5. Responsiveness
 *    Using `vh` for `max-height` to fix image proportions after resize in Safari and Opera
 *    Using `vh` and `vw` to make responsive image work in IE11
 * 6. Suppress outline on focus
 */
.uk-lightbox-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: none;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: rgba(255, 255, 255, 0.7);
  /* 4 */
  will-change: transform, opacity;
}

/* 5 */
.uk-lightbox-items > * > * {
  max-width: 100vw;
  max-height: 100vh;
}

/* 6 */
.uk-lightbox-items > :focus {
  outline: none;
}

.uk-lightbox-items > * > :not(iframe) {
  width: auto;
  height: auto;
}

.uk-lightbox-items > .uk-active {
  display: flex;
}

/* Toolbar
 ========================================================================== */
.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
}

.uk-lightbox-toolbar > * {
  color: rgba(255, 255, 255, 0.7);
}

/* Toolbar Icon (Close)
 ========================================================================== */
.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7);
}

/*
 * Hover
 */
.uk-lightbox-toolbar-icon:hover {
  color: #fff;
}

/* Button (Slidenav)
 ========================================================================== */
/*
 * 1. Center icon vertically and horizontally
 */
.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/*
 * Hover
 */
.uk-lightbox-button:hover {
  color: #fff;
}

/* Caption
 ========================================================================== */
.uk-lightbox-caption:empty {
  display: none;
}

/* Iframe
 ========================================================================== */
.uk-lightbox-iframe {
  width: 80%;
  height: 80%;
}

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
}

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto;
}

/* Bar
 ========================================================================== */
/*
 * 1. Set position
 * 2. Size and style
 * 3. Allow scrolling
 */
.uk-offcanvas-bar {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -360px;
  /* 2 */
  box-sizing: border-box;
  width: 360px;
  padding: 0 0;
  background: white;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-offcanvas-bar {
    left: -350px;
    width: 350px;
    padding: 0 0;
  }
}
/* Flip modifier */
.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -360px;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -350px;
  }
}
/*
 * Open
 */
.uk-open > .uk-offcanvas-bar {
  left: 0;
}

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  left: auto;
  right: 0;
}

/*
 * Slide Animation (Used in slide and push mode)
 */
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out;
}

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right;
}

/*
 * Reveal Animation
 * 1. Set position
 * 2. Clip the bar
 * 3. Animation
 * 4. Reset position
 */
.uk-offcanvas-reveal {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 0;
  overflow: hidden;
  /* 3 */
  transition: width 0.3s ease-out;
}

.uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: 0;
}

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: auto;
  right: 0;
}

.uk-open > .uk-offcanvas-reveal {
  width: 360px;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-open > .uk-offcanvas-reveal {
    width: 350px;
  }
}
/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-offcanvas-close {
  position: absolute;
  z-index: 1001;
  top: 20px;
  right: 20px;
  padding: 5px;
}

/* Overlay
 ========================================================================== */
/*
 * Overlay the whole page. Needed for the `::before`
 * 1. Using `100vw` so no modification is needed when off-canvas is flipped
 * 2. Allow for closing with swipe gesture on devices with pointer events.
 */
.uk-offcanvas-overlay {
  /* 1 */
  width: 100vw;
  /* 2 */
  touch-action: none;
}

/*
 * 1. Mask the whole page
 * 2. Fade-in transition
 */
.uk-offcanvas-overlay::before {
  /* 1 */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  /* 2 */
  opacity: 0;
  transition: opacity 0.15s linear;
}

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1;
}

/* Prevent scrolling
 ========================================================================== */
/*
 * Prevent horizontal scrollbar when the content is slide-out
 * Has to be on the `html` element too to make it work on the `body`
 */
.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden;
}

/* Container
 ========================================================================== */
/*
 * Prepare slide-out animation (Used in reveal and push mode)
 * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
 * lose their fixed state and behaves like `absolute` within a transformed container
 * 1. Provide a fixed width and prevent shrinking
 */
.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  /* 1 */
  box-sizing: border-box;
  width: 100%;
}

/*
 * Activate slide-out animation
 */
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 360px;
}

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -360px;
}

/* Tablet landscape and bigger */
@media (min-width: 900px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 350px;
  }

  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -350px;
  }
}
/* ========================================================================
   Component: Notification
 ========================================================================== */
/*
 * 1. Set position
 * 2. Dimensions
 */
.uk-notification {
  /* 1 */
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  /* 2 */
  box-sizing: border-box;
  width: 350px;
}

/* Position modifiers
========================================================================== */
.uk-notification-top-right,
.uk-notification-bottom-right {
  left: auto;
  right: 10px;
}

.uk-notification-top-center,
.uk-notification-bottom-center {
  left: 50%;
  margin-left: -175px;
}

.uk-notification-bottom-left,
.uk-notification-bottom-right,
.uk-notification-bottom-center {
  top: auto;
  bottom: 10px;
}

/* Responsiveness
========================================================================== */
/* Phones portrait and smaller */
@media (max-width: 639px) {
  .uk-notification {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0;
  }
}
/* Message
========================================================================== */
.uk-notification-message {
  position: relative;
  padding: 15px;
  background: #f8f8f8;
  color: #666;
  font-size: 1.25rem;
  line-height: 1.4;
  cursor: pointer;
  padding: 24px 32px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-top: 4px var(--notification-color) solid;
}
@media (max-width: 639px) {
  .uk-notification-message {
    padding: 16px 24px;
  }
}
:root .uk-notification-message {
  color: var(--notification-color);
}

* + .uk-notification-message {
  margin-top: 10px;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-notification-close {
  display: none;
  position: absolute;
  top: 20px;
  right: 15px;
}
@media (hover: none) {
  .uk-notification-close {
    display: block;
  }
}

.uk-notification-message:hover .uk-notification-close {
  display: block;
}

/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-notification-message-primary {
  color: #1e87f0;
  --notification-color: #2289e9;
}

/*
 * Success
 */
.uk-notification-message-success {
  color: #32d296;
  --notification-color: #3cae39;
}

/*
 * Warning
 */
.uk-notification-message-warning {
  color: #faa05a;
  --notification-color: #f78018;
}

/*
 * Danger
 */
.uk-notification-message-danger {
  color: #f0506e;
  --notification-color: #f22814;
}

/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*=uk-animation-] {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear;
}

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade-scale-02;
}

.uk-animation-scale-down {
  animation-name: uk-fade-scale-18;
}

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade-top;
}

.uk-animation-slide-bottom {
  animation-name: uk-fade-bottom;
}

.uk-animation-slide-left {
  animation-name: uk-fade-left;
}

.uk-animation-slide-right {
  animation-name: uk-fade-right;
}

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade-top-small;
}

.uk-animation-slide-bottom-small {
  animation-name: uk-fade-bottom-small;
}

.uk-animation-slide-left-small {
  animation-name: uk-fade-left-small;
}

.uk-animation-slide-right-small {
  animation-name: uk-fade-right-small;
}

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade-top-medium;
}

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade-bottom-medium;
}

.uk-animation-slide-left-medium {
  animation-name: uk-fade-left-medium;
}

.uk-animation-slide-right-medium {
  animation-name: uk-fade-right-medium;
}

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-scale-kenburns;
  animation-duration: 15s;
}

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake;
}

/*
 * SVG Stroke
 * The `--uk-animation-stroke` custom property contains the longest path length.
 * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
 * All strokes are animated by the same pace and doesn't end simultaneously.
 * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
 */
.uk-animation-stroke {
  animation-name: uk-stroke;
  stroke-dasharray: var(--uk-animation-stroke);
  animation-duration: 2s;
}

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in;
}

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s;
}

/* Toggle (Hover + Focus)
========================================================================== */
/*
 * The toggle is triggered on touch devices using `:focus` and tabindex
 */
.uk-animation-toggle:not(:hover):not(:focus) [class*=uk-animation-] {
  animation-name: none;
}

/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-animation-toggle {
  /* 1 */
  -webkit-tap-highlight-color: transparent;
}

/*
 * Remove outline for `tabindex`
 */
.uk-animation-toggle:focus {
  outline: none;
}

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*
 * Slide Top
 */
@keyframes uk-fade-top {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Slide Bottom
 */
@keyframes uk-fade-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Slide Left
 */
@keyframes uk-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*
 * Slide Right
 */
@keyframes uk-fade-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*
 * Slide Top Small
 */
@keyframes uk-fade-top-small {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Slide Bottom Small
 */
@keyframes uk-fade-bottom-small {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Slide Left Small
 */
@keyframes uk-fade-left-small {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*
 * Slide Right Small
 */
@keyframes uk-fade-right-small {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*
 * Slide Top Medium
 */
@keyframes uk-fade-top-medium {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Slide Bottom Medium
 */
@keyframes uk-fade-bottom-medium {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Slide Left Medium
 */
@keyframes uk-fade-left-medium {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*
 * Slide Right Medium
 */
@keyframes uk-fade-right-medium {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*
 * Scale Up
 */
@keyframes uk-fade-scale-02 {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/*
 * Scale Down
 */
@keyframes uk-fade-scale-18 {
  0% {
    opacity: 0;
    transform: scale(1.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/*
 * Kenburns
 */
@keyframes uk-scale-kenburns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
/*
 * Shake
 */
@keyframes uk-shake {
  0%, 100% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-9px);
  }
  20% {
    transform: translateX(8px);
  }
  30% {
    transform: translateX(-7px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}
/*
 * Stroke
 */
@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
/* ========================================================================
   Component: Utility
 ========================================================================== */
/* Panel
 ========================================================================== */
.uk-panel {
  position: relative;
  box-sizing: border-box;
}

/*
 * Micro clearfix
 */
.uk-panel::before,
.uk-panel::after {
  content: "";
  display: table;
}

.uk-panel::after {
  clear: both;
}

/*
 * Remove margin from the last-child
 */
.uk-panel > :last-child {
  margin-bottom: 0;
}

/*
 * Scrollable
 */
.uk-panel-scrollable {
  height: 170px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  resize: both;
}

/* Clearfix
 ========================================================================== */
/*
 * 1. `table-cell` is used with `::before` because `table` creates a 1px gap when it becomes a flex item, only in Webkit
 * 2. `table` is used again with `::after` because `clear` only works with block elements.
 * Note: `display: block` with `overflow: hidden` is currently not working in the latest Safari
 */
/* 1 */
.uk-clearfix::before {
  content: "";
  display: table-cell;
}

/* 2 */
.uk-clearfix::after {
  content: "";
  display: table;
  clear: both;
}

/* Float
 ========================================================================== */
/*
 * 1. Prevent content overflow
 */
.uk-float-left {
  float: left;
}

.uk-float-right {
  float: right;
}

/* 1 */
[class*=uk-float-] {
  max-width: 100%;
}

/* Overfow
 ========================================================================== */
.uk-overflow-hidden {
  overflow: hidden;
}

/*
 * Enable scrollbars if content is clipped
 * Note: Firefox ignores `padding-bottom` for the scrollable overflow https://bugzilla.mozilla.org/show_bug.cgi?id=748518
 */
.uk-overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.uk-overflow-auto > :last-child {
  margin-bottom: 0;
}

/* Resize
 ========================================================================== */
.uk-resize {
  resize: both;
}

.uk-resize-vertical {
  resize: vertical;
}

/* Display
 ========================================================================== */
.uk-display-block {
  display: block !important;
}

.uk-display-inline {
  display: inline !important;
}

.uk-display-inline-block {
  display: inline-block !important;
}

/* Inline
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 * 5. Force hardware acceleration without creating a new stacking context
 *    to fix 1px glitch when combined with overlays and transitions in Webkit
 * 6. Clip child elements
 */
[class*=uk-inline] {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  -webkit-backface-visibility: hidden;
}

.uk-inline-clip {
  /* 6 */
  overflow: hidden;
}

/* Responsive objects
 ========================================================================== */
/*
 * Preserve original dimensions
 * Because `img, `video`, `canvas` and  `audio` are already responsive by default, see Base component
 */
.uk-preserve-width,
.uk-preserve-width canvas,
.uk-preserve-width img,
.uk-preserve-width svg,
.uk-preserve-width video {
  max-width: none;
}

/*
 * Responsiveness
 * Corrects `max-width` and `max-height` behavior if padding and border are used
 */
.uk-responsive-width,
.uk-responsive-height {
  box-sizing: border-box;
}

/*
 * 1. Set a maximum width. `important` needed to override `uk-preserve-width img`
 * 2. Auto scale the height. Only needed if `height` attribute is present
 */
.uk-responsive-width {
  /* 1 */
  max-width: 100% !important;
  /* 2 */
  height: auto;
}

/*
 * 1. Set a maximum height. Only works if the parent element has a fixed height
 * 2. Auto scale the width. Only needed if `width` attribute is present
 * 3. Reset max-width, which `img, `video`, `canvas` and  `audio` already have by default
 */
.uk-responsive-height {
  /* 1 */
  max-height: 100%;
  /* 2 */
  width: auto;
  /* 3 */
  max-width: none;
}

/* Border
 ========================================================================== */
.uk-border-circle {
  border-radius: 50%;
}

.uk-border-pill {
  border-radius: 500px;
}

.uk-border-rounded {
  border-radius: 5px;
}

/*
 * Fix `overflow: hidden` to be ignored with border-radius and CSS transforms in Webkit
 */
.uk-inline-clip[class*=uk-border-] {
  -webkit-transform: translateZ(0);
}

/* Box-shadow
 ========================================================================== */
.uk-box-shadow-small {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-medium {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-large {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.uk-box-shadow-xlarge {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
}

/*
 * Hover
 */
[class*=uk-box-shadow-hover] {
  transition: box-shadow 0.1s ease-in-out;
}

.uk-box-shadow-hover-small:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-hover-medium:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-hover-large:hover {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.uk-box-shadow-hover-xlarge:hover {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
}

/* Box-shadow bottom
 ========================================================================== */
/*
 * 1. Set position.
 * 2. Set style
 * 3. Blur doesn't work on pseudo elements with negative `z-index` in Edge.
 *    Solved by using `before` and add position context to child elements.
 */
@supports (filter: blur(0)) {
  .uk-box-shadow-bottom {
    display: inline-block;
    position: relative;
    max-width: 100%;
    vertical-align: middle;
  }

  .uk-box-shadow-bottom::before {
    content: "";
    /* 1 */
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    /* 2 */
    height: 30px;
    border-radius: 100%;
    background: #444;
    filter: blur(20px);
  }

  /* 3 */
  .uk-box-shadow-bottom > * {
    position: relative;
  }
}
/* Drop cap
 ========================================================================== */
/*
 * 1. Firefox doesn't apply `::first-letter` if the first letter is inside child elements
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=214004
 * 2. In Firefox, a floating `::first-letter` doesn't have a line box and there for no `line-height`
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=317933
 * 3. Caused by 1.: Edge creates two nested `::first-letter` containers, one for each selector
 *    This doubles the `font-size` exponential when using the `em` unit.
 */
.uk-dropcap::first-letter,
.uk-dropcap > p:first-of-type::first-letter {
  display: block;
  margin-right: 10px;
  float: left;
  font-size: 4.5em;
  line-height: 1;
}

/* 2 */
@-moz-document url-prefix() {
  .uk-dropcap::first-letter,
.uk-dropcap > p:first-of-type::first-letter {
    margin-top: 1.1%;
  }
}
/* 3 */
@supports (-ms-ime-align: auto) {
  .uk-dropcap > p:first-of-type::first-letter {
    font-size: 1em;
  }
}
/* Logo
 ========================================================================== */
/*
 * 1. Required for `a`
 */
.uk-logo {
  font-size: 1.5rem;
  font-family: Poppins, sans-serif;
  color: #666;
  /* 1 */
  text-decoration: none;
}

/* Hover + Focus */
.uk-logo:hover,
.uk-logo:focus {
  color: #666;
  outline: none;
  /* 1 */
  text-decoration: none;
}

.uk-logo-inverse {
  display: none;
}

/* Disabled State
 ========================================================================== */
.uk-disabled {
  pointer-events: none;
}

/* Drag State
 ========================================================================== */
/*
 * 1. Needed if moving over elements with have their own cursor on hover, e.g. links or buttons
 * 2. Fix dragging over iframes
 */
.uk-drag,
.uk-drag * {
  cursor: move;
}

/* 2 */
.uk-drag iframe {
  pointer-events: none;
}

/* Dragover State
 ========================================================================== */
/*
 * Create a box-shadow when dragging a file over the upload area
 */
.uk-dragover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.3);
}

/* Blend modes
 ========================================================================== */
.uk-blend-multiply {
  mix-blend-mode: multiply;
}

.uk-blend-screen {
  mix-blend-mode: screen;
}

.uk-blend-overlay {
  mix-blend-mode: overlay;
}

.uk-blend-darken {
  mix-blend-mode: darken;
}

.uk-blend-lighten {
  mix-blend-mode: lighten;
}

.uk-blend-color-dodge {
  mix-blend-mode: color-dodge;
}

.uk-blend-color-burn {
  mix-blend-mode: color-burn;
}

.uk-blend-hard-light {
  mix-blend-mode: hard-light;
}

.uk-blend-soft-light {
  mix-blend-mode: soft-light;
}

.uk-blend-difference {
  mix-blend-mode: difference;
}

.uk-blend-exclusion {
  mix-blend-mode: exclusion;
}

.uk-blend-hue {
  mix-blend-mode: hue;
}

.uk-blend-saturation {
  mix-blend-mode: saturation;
}

.uk-blend-color {
  mix-blend-mode: color;
}

.uk-blend-luminosity {
  mix-blend-mode: luminosity;
}

/* Transform
========================================================================== */
.uk-transform-center {
  transform: translate(-50%, -50%);
}

/* Transform Origin
========================================================================== */
.uk-transform-origin-top-left {
  transform-origin: 0 0;
}

.uk-transform-origin-top-center {
  transform-origin: 50% 0;
}

.uk-transform-origin-top-right {
  transform-origin: 100% 0;
}

.uk-transform-origin-center-left {
  transform-origin: 0 50%;
}

.uk-transform-origin-center-right {
  transform-origin: 100% 50%;
}

.uk-transform-origin-bottom-left {
  transform-origin: 0 100%;
}

.uk-transform-origin-bottom-center {
  transform-origin: 50% 100%;
}

.uk-transform-origin-bottom-right {
  transform-origin: 100% 100%;
}

/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: flex;
}

.uk-flex-inline {
  display: inline-flex;
}

/*
 * Remove pseudo elements created by micro clearfix as precaution
 */
.uk-flex::before,
.uk-flex::after,
.uk-flex-inline::before,
.uk-flex-inline::after {
  display: none;
}

/* Alignment
 ========================================================================== */
/*
 * Align items along the main axis of the current line of the flex container
 * Row: Horizontal
 */
.uk-flex-left {
  justify-content: flex-start;
}

.uk-flex-center {
  justify-content: center;
}

.uk-flex-right {
  justify-content: flex-end;
}

.uk-flex-between {
  justify-content: space-between;
}

.uk-flex-around {
  justify-content: space-around;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start;
  }

  .uk-flex-center\@s {
    justify-content: center;
  }

  .uk-flex-right\@s {
    justify-content: flex-end;
  }

  .uk-flex-between\@s {
    justify-content: space-between;
  }

  .uk-flex-around\@s {
    justify-content: space-around;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-flex-left\@m {
    justify-content: flex-start;
  }

  .uk-flex-center\@m {
    justify-content: center;
  }

  .uk-flex-right\@m {
    justify-content: flex-end;
  }

  .uk-flex-between\@m {
    justify-content: space-between;
  }

  .uk-flex-around\@m {
    justify-content: space-around;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-flex-left\@l {
    justify-content: flex-start;
  }

  .uk-flex-center\@l {
    justify-content: center;
  }

  .uk-flex-right\@l {
    justify-content: flex-end;
  }

  .uk-flex-between\@l {
    justify-content: space-between;
  }

  .uk-flex-around\@l {
    justify-content: space-around;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-flex-left\@xl {
    justify-content: flex-start;
  }

  .uk-flex-center\@xl {
    justify-content: center;
  }

  .uk-flex-right\@xl {
    justify-content: flex-end;
  }

  .uk-flex-between\@xl {
    justify-content: space-between;
  }

  .uk-flex-around\@xl {
    justify-content: space-around;
  }
}
/*
 * Align items in the cross axis of the current line of the flex container
 * Row: Vertical
 */
.uk-flex-stretch {
  align-items: stretch;
}

.uk-flex-top {
  align-items: flex-start;
}

.uk-flex-middle {
  align-items: center;
}

.uk-flex-bottom {
  align-items: flex-end;
}

/* Direction
 ========================================================================== */
.uk-flex-row {
  flex-direction: row;
}

.uk-flex-row-reverse {
  flex-direction: row-reverse;
}

.uk-flex-column {
  flex-direction: column;
}

.uk-flex-column-reverse {
  flex-direction: column-reverse;
}

/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  flex-wrap: nowrap;
}

.uk-flex-wrap {
  flex-wrap: wrap;
}

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/*
 * Aligns items within the flex container when there is extra space in the cross-axis
 * Only works if there is more than one line of flex items
 */
.uk-flex-wrap-stretch {
  align-content: stretch;
}

.uk-flex-wrap-top {
  align-content: flex-start;
}

.uk-flex-wrap-middle {
  align-content: center;
}

.uk-flex-wrap-bottom {
  align-content: flex-end;
}

.uk-flex-wrap-between {
  align-content: space-between;
}

.uk-flex-wrap-around {
  align-content: space-around;
}

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-first {
  order: -1;
}

.uk-flex-last {
  order: 99;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1;
  }

  .uk-flex-last\@s {
    order: 99;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-flex-first\@m {
    order: -1;
  }

  .uk-flex-last\@m {
    order: 99;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-flex-first\@l {
    order: -1;
  }

  .uk-flex-last\@l {
    order: 99;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-flex-first\@xl {
    order: -1;
  }

  .uk-flex-last\@xl {
    order: 99;
  }
}
/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-none {
  flex: none;
}

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 */
.uk-flex-auto {
  flex: auto;
}

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-1 {
  flex: 1;
}

/* ========================================================================
   Component: Margin
 ========================================================================== */
/*
 * Default
 */
.uk-margin {
  margin-bottom: 20px;
}

* + .uk-margin {
  margin-top: 20px !important;
}

.uk-margin-top {
  margin-top: 20px !important;
}

.uk-margin-bottom {
  margin-bottom: 20px !important;
}

.uk-margin-left {
  margin-left: 20px !important;
}

.uk-margin-right {
  margin-right: 20px !important;
}

/* Small
 ========================================================================== */
.uk-margin-small {
  margin-bottom: 10px;
}

* + .uk-margin-small {
  margin-top: 10px !important;
}

.uk-margin-small-top {
  margin-top: 10px !important;
}

.uk-margin-small-bottom {
  margin-bottom: 10px !important;
}

.uk-margin-small-left {
  margin-left: 10px !important;
}

.uk-margin-small-right {
  margin-right: 10px !important;
}

/* Medium
 ========================================================================== */
.uk-margin-medium {
  margin-bottom: 40px;
}

* + .uk-margin-medium {
  margin-top: 40px !important;
}

.uk-margin-medium-top {
  margin-top: 40px !important;
}

.uk-margin-medium-bottom {
  margin-bottom: 40px !important;
}

.uk-margin-medium-left {
  margin-left: 40px !important;
}

.uk-margin-medium-right {
  margin-right: 40px !important;
}

/* Large
 ========================================================================== */
.uk-margin-large {
  margin-bottom: 40px;
}

* + .uk-margin-large {
  margin-top: 40px !important;
}

.uk-margin-large-top {
  margin-top: 40px !important;
}

.uk-margin-large-bottom {
  margin-bottom: 40px !important;
}

.uk-margin-large-left {
  margin-left: 40px !important;
}

.uk-margin-large-right {
  margin-right: 40px !important;
}

/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-margin-large {
    margin-bottom: 70px;
  }

  * + .uk-margin-large {
    margin-top: 70px !important;
  }

  .uk-margin-large-top {
    margin-top: 70px !important;
  }

  .uk-margin-large-bottom {
    margin-bottom: 70px !important;
  }

  .uk-margin-large-left {
    margin-left: 70px !important;
  }

  .uk-margin-large-right {
    margin-right: 70px !important;
  }
}
/* XLarge
 ========================================================================== */
.uk-margin-xlarge {
  margin-bottom: 70px;
}

* + .uk-margin-xlarge {
  margin-top: 70px !important;
}

.uk-margin-xlarge-top {
  margin-top: 70px !important;
}

.uk-margin-xlarge-bottom {
  margin-bottom: 70px !important;
}

.uk-margin-xlarge-left {
  margin-left: 70px !important;
}

.uk-margin-xlarge-right {
  margin-right: 70px !important;
}

/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-margin-xlarge {
    margin-bottom: 140px;
  }

  * + .uk-margin-xlarge {
    margin-top: 140px !important;
  }

  .uk-margin-xlarge-top {
    margin-top: 140px !important;
  }

  .uk-margin-xlarge-bottom {
    margin-bottom: 140px !important;
  }

  .uk-margin-xlarge-left {
    margin-left: 140px !important;
  }

  .uk-margin-xlarge-right {
    margin-right: 140px !important;
  }
}
/* Auto
 ========================================================================== */
.uk-margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.uk-margin-auto-top {
  margin-top: auto !important;
}

.uk-margin-auto-bottom {
  margin-bottom: auto !important;
}

.uk-margin-auto-left {
  margin-left: auto !important;
}

.uk-margin-auto-right {
  margin-right: auto !important;
}

.uk-margin-auto-vertical {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-auto\@s {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@s {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@s {
    margin-right: auto !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-margin-auto\@m {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@m {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@m {
    margin-right: auto !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-margin-auto\@l {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@l {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@l {
    margin-right: auto !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-margin-auto\@xl {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-auto-left\@xl {
    margin-left: auto !important;
  }

  .uk-margin-auto-right\@xl {
    margin-right: auto !important;
  }
}
/* Remove
 ========================================================================== */
.uk-margin-remove {
  margin: 0 !important;
}

.uk-margin-remove-top {
  margin-top: 0 !important;
}

.uk-margin-remove-bottom {
  margin-bottom: 0 !important;
}

.uk-margin-remove-left {
  margin-left: 0 !important;
}

.uk-margin-remove-right {
  margin-right: 0 !important;
}

.uk-margin-remove-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.uk-margin-remove-adjacent + *,
.uk-margin-remove-first-child > :first-child {
  margin-top: 0 !important;
}

.uk-margin-remove-last-child > :last-child {
  margin-bottom: 0 !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-remove-left\@s {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@s {
    margin-right: 0 !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-margin-remove-left\@m {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@m {
    margin-right: 0 !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-margin-remove-left\@l {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@l {
    margin-right: 0 !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-margin-remove-left\@xl {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@xl {
    margin-right: 0 !important;
  }
}
/* ========================================================================
   Component: Position
 ========================================================================== */
/* Directions
 ========================================================================== */
/*
 * 1. Prevent content overflow if `max-width: 100%` is used inside position container.
 */
[class*=uk-position-top],
[class*=uk-position-bottom],
[class*=uk-position-left],
[class*=uk-position-right],
[class*=uk-position-center] {
  position: absolute !important;
  /* 1 */
  max-width: 100%;
}

/* Edges
 ========================================================================== */
/* Don't use `width: 100%` because it is wrong if the parent has padding. */
.uk-position-top {
  top: 0;
  left: 0;
  right: 0;
}

.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.uk-position-left {
  top: 0;
  bottom: 0;
  left: 0;
}

.uk-position-right {
  top: 0;
  bottom: 0;
  right: 0;
}

/* Corners
 ========================================================================== */
.uk-position-top-left {
  top: 0;
  left: 0;
}

.uk-position-top-right {
  top: 0;
  right: 0;
}

.uk-position-bottom-left {
  bottom: 0;
  left: 0;
}

.uk-position-bottom-right {
  bottom: 0;
  right: 0;
}

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 */
.uk-position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 1 */
  width: max-content;
  max-width: 100%;
  box-sizing: border-box;
}

/* Vertical */
[class*=uk-position-center-left],
[class*=uk-position-center-right] {
  top: 50%;
  transform: translateY(-50%);
}

.uk-position-center-left {
  left: 0;
}

.uk-position-center-right {
  right: 0;
}

.uk-position-center-left-out {
  right: 100%;
  width: max-content;
}

.uk-position-center-right-out {
  left: 100%;
  width: max-content;
}

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center {
  left: 50%;
  transform: translateX(-50%);
  /* 1 */
  width: max-content;
  max-width: 100%;
  box-sizing: border-box;
}

.uk-position-top-center {
  top: 0;
}

.uk-position-bottom-center {
  bottom: 0;
}

/* Cover
 ========================================================================== */
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Utility
 ========================================================================== */
.uk-position-relative {
  position: relative !important;
}

.uk-position-absolute {
  position: absolute !important;
}

.uk-position-fixed {
  position: fixed !important;
}

.uk-position-z-index {
  z-index: 1;
}

/* Margin modifier
 ========================================================================== */
/*
 * Small
 */
.uk-position-small {
  max-width: calc(100% - (15px * 2));
  margin: 15px;
}

.uk-position-small.uk-position-center {
  transform: translate(-50%, -50%) translate(-15px, -15px);
}

.uk-position-small[class*=uk-position-center-left],
.uk-position-small[class*=uk-position-center-right] {
  transform: translateY(-50%) translateY(-15px);
}

.uk-position-small.uk-position-top-center,
.uk-position-small.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-15px);
}

/*
 * Medium
 */
.uk-position-medium {
  max-width: calc(100% - (30px * 2));
  margin: 30px;
}

.uk-position-medium.uk-position-center {
  transform: translate(-50%, -50%) translate(-30px, -30px);
}

.uk-position-medium[class*=uk-position-center-left],
.uk-position-medium[class*=uk-position-center-right] {
  transform: translateY(-50%) translateY(-30px);
}

.uk-position-medium.uk-position-top-center,
.uk-position-medium.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-30px);
}

/*
 * Large
 */
.uk-position-large {
  max-width: calc(100% - (30px * 2));
  margin: 30px;
}

.uk-position-large.uk-position-center {
  transform: translate(-50%, -50%) translate(-30px, -30px);
}

.uk-position-large[class*=uk-position-center-left],
.uk-position-large[class*=uk-position-center-right] {
  transform: translateY(-50%) translateY(-30px);
}

.uk-position-large.uk-position-top-center,
.uk-position-large.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-30px);
}

/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-position-large {
    max-width: calc(100% - (50px * 2));
    margin: 50px;
  }

  .uk-position-large.uk-position-center {
    transform: translate(-50%, -50%) translate(-50px, -50px);
  }

  .uk-position-large[class*=uk-position-center-left],
.uk-position-large[class*=uk-position-center-right] {
    transform: translateY(-50%) translateY(-50px);
  }

  .uk-position-large.uk-position-top-center,
.uk-position-large.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-50px);
  }
}
/* ========================================================================
   Component: Visibility
 ========================================================================== */
/*
 * Hidden
 * `hidden` attribute also set here to make it stronger
 */
[hidden],
.uk-hidden {
  display: none !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-hidden\@s {
    display: none !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 900px) {
  .uk-hidden\@m {
    display: none !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1000px) {
  .uk-hidden\@l {
    display: none !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1200px) {
  .uk-hidden\@xl {
    display: none !important;
  }
}
/*
 * Visible
 */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-visible\@s {
    display: none !important;
  }
}
/* Phone landscape and smaller */
@media (max-width: 899px) {
  .uk-visible\@m {
    display: none !important;
  }
}
/* Tablet landscape and smaller */
@media (max-width: 999px) {
  .uk-visible\@l {
    display: none !important;
  }
}
/* Desktop and smaller */
@media (max-width: 1199px) {
  .uk-visible\@xl {
    display: none !important;
  }
}
/* Visibility
 ========================================================================== */
.uk-invisible {
  visibility: hidden !important;
}

/* Toggle (Hover + Focus)
 ========================================================================== */
/*
 * Hidden
 * 1. The toggle is triggered on touch devices using `:focus` and tabindex
 * 2. The target stays visible if any element within receives focus through keyboard
 *    Doesn't work in Edge, yet.
 * 3. Can't use `display: none` nor `visibility: hidden` because both are not focusable.
 *
 */
/* 1 + 2 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
  /* 3 */
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}

/*
 * Invisible
 */
/* 1 + 2 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
  /* 3 */
  opacity: 0 !important;
}

/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-visible-toggle {
  /* 1 */
  -webkit-tap-highlight-color: transparent;
}

/*
 * Remove outline for `tabindex`
 */
.uk-visible-toggle:focus {
  outline: none;
}

/* Touch
 ========================================================================== */
/*
 * Hide if primary pointing device has limited accuracy, e.g. a touch screen.
 * Works on mobile browsers: Safari, Chrome and Android browser
 */
@media (pointer: coarse) {
  .uk-hidden-touch {
    display: none !important;
  }
}
/*
 * Hide if primary pointing device is accurate, e.g. mouse.
 * 1. Fallback for IE11 and Firefox, because `pointer` is not supported
 * 2. Reset if supported
 */
/* 1 */
.uk-hidden-notouch {
  display: none !important;
}

@media (pointer: coarse) {
  .uk-hidden-notouch {
    display: block !important;
  }
}
/* ========================================================================
   Component: Print
 ========================================================================== */
@media print {
  *,
*::before,
*::after {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
a:visited {
    text-decoration: underline;
  }

  pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }
}
@keyframes shine-loader {
  100% {
    background-position: -100%;
  }
}
img:-moz-loading {
  color: transparent;
}

:root .yall-loaded {
  background: transparent none;
  animation: none;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px var(--form-active-background) inset;
  -webkit-text-fill-color: var(--form-active-color);
}

.tableWrapper {
  overflow: auto;
}

.YoutubeVideo__playButtonIconWrapper, .YoutubeVideo__playButton, .CookiesMessage, .Button__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileBurger::before, .MobileBurger::after, .StepsList__image, .CardImage__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.StepsList__image, .CardImage__image {
  object-fit: cover;
}

.CardImage__placeholder {
  position: absolute;
  width: 62px;
  height: 62px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.SiteFooter__logo, .SiteHeader__iconLink, .SiteHeader__logo {
  transition: opacity 0.2s ease-in-out;
  outline: none;
}
.SiteFooter__logo:hover, .SiteHeader__iconLink:hover, .SiteHeader__logo:hover, .SiteFooter__logo:focus, .SiteHeader__iconLink:focus, .SiteHeader__logo:focus {
  opacity: 0.7;
}
.SiteFooter__logo:active, .SiteHeader__iconLink:active, .SiteHeader__logo:active {
  opacity: 0.5;
}

.Layout__title,
.LandingBlock__text--main,
.BenefitsList__title,
.Layout__title--xlarge,
.SiteForm__title,
.SiteModal__title {
  line-height: 1.6;
  font-weight: 500;
}

.SiteForm__subtitle,
.ProjectCard__location,
.LandingBlock__text--additional {
  line-height: 1.8;
}

.Layout__title {
  font-size: 2.375rem;
}
@media (max-width: 1199px) {
  .Layout__title {
    font-size: 2.25rem;
  }
}
@media (max-width: 999px) {
  .Layout__title {
    font-size: 2.125rem;
  }
}
@media (max-width: 899px) {
  .Layout__title {
    font-size: 2rem;
  }
}
@media (max-width: 639px) {
  .Layout__title {
    font-size: 1.75rem;
  }
}

.LandingBlock__text--main, .BenefitsList__title, .Layout__title--xlarge, .SiteForm__title, .SiteModal__title {
  font-size: 2rem;
}
@media (max-width: 1199px) {
  .LandingBlock__text--main, .BenefitsList__title, .Layout__title--xlarge, .SiteForm__title, .SiteModal__title {
    font-size: 1.875rem;
  }
}
@media (max-width: 999px) {
  .LandingBlock__text--main, .BenefitsList__title, .Layout__title--xlarge, .SiteForm__title, .SiteModal__title {
    font-size: 1.75rem;
  }
}
@media (max-width: 899px) {
  .LandingBlock__text--main, .BenefitsList__title, .Layout__title--xlarge, .SiteForm__title, .SiteModal__title {
    font-size: 1.5rem;
  }
}
@media (max-width: 639px) {
  .LandingBlock__text--main, .BenefitsList__title, .Layout__title--xlarge, .SiteForm__title, .SiteModal__title {
    font-size: 1.375rem;
  }
}

.SiteForm__subtitle {
  font-size: 1.5rem;
}
@media (max-width: 999px) {
  .SiteForm__subtitle {
    font-size: 1.25rem;
  }
}
@media (max-width: 639px) {
  .SiteForm__subtitle {
    font-size: 1.125rem;
  }
}

.ProjectCard__location {
  font-size: 1.25rem;
}
@media (max-width: 999px) {
  .ProjectCard__location {
    font-size: 1.125rem;
  }
}
@media (max-width: 639px) {
  .ProjectCard__location {
    font-size: 1rem;
  }
}

.LandingBlock__text--additional {
  font-size: 1.125rem;
}
@media (max-width: 999px) {
  .LandingBlock__text--additional {
    font-size: 1rem;
  }
}

.Button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  vertical-align: middle;
  appearance: none;
  min-width: 176px;
  height: 68px;
  margin: 0;
  padding: 0 40px;
  font: inherit;
  font-weight: 500;
  font-size: 1rem;
  white-space: nowrap;
  text-decoration: none;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
  transition: 0.25s ease-in-out;
  transition-property: color, border, background-color;
  color: var(--button-default-color);
  border-color: var(--button-default-border);
  background-color: var(--button-default-background);
}
.Button .Button__icon {
  color: var(--button-default-icon-color, currentColor);
}
.Button:hover {
  color: var(--button-hover-color);
  border-color: var(--button-hover-border);
  background-color: var(--button-hover-background);
}
.Button:hover .Button__icon {
  color: var(--button-hover-icon-color, currentColor);
}
.Button:focus {
  color: var(--button-focus-color);
  border-color: var(--button-focus-border);
  background-color: var(--button-focus-background);
}
.Button:focus .Button__icon {
  color: var(--button-focus-icon-color, currentColor);
}
.Button:active {
  color: var(--button-active-color);
  border-color: var(--button-active-border);
  background-color: var(--button-active-background);
}
.Button:active .Button__icon {
  color: var(--button-active-icon-color, currentColor);
}
.Button:disabled {
  color: var(--button-disabled-color);
  border-color: var(--button-disabled-border);
  background-color: var(--button-disabled-background);
}
.Button:disabled .Button__icon {
  color: var(--button-disabled-icon-color, currentColor);
}
@media (max-width: 1199px) {
  .Button {
    height: 64px;
    padding-x: 32px;
  }
}
@media (max-width: 424px) {
  .Button {
    height: 56px;
    font-size: 0.875rem;
  }
}
@media (max-width: 369px) {
  .Button {
    width: 100%;
  }
}
.Button:hover, .Button:focus, .Button:active {
  outline: none;
}
.Button:disabled {
  border-width: 1px;
}
.Button:not(:disabled) {
  cursor: pointer;
}
.Button--active {
  color: var(--button-active-color);
  border-color: var(--button-active-border);
  background-color: var(--button-active-background);
  pointer-events: none;
}
.Button--active .Button__icon {
  color: var(--button-active-icon-color, currentColor);
}
.Button--noEvents {
  pointer-events: none;
}
:root .Button--loading .Button__spinner {
  display: block;
}
:root .Button--loading .Button__text {
  visibility: hidden;
}
.Button--black {
  --button-default-color:white;
  --button-default-border:#2b2f34;
  --button-default-background:#2b2f34;
  --button-hover-color:white;
  --button-hover-border:#225493;
  --button-hover-background:#225493;
  --button-focus-color:white;
  --button-focus-border:#929bad;
  --button-focus-background:#2b2f34;
  --button-active-color:white;
  --button-active-border:#297df8;
  --button-active-background:#297df8;
  --button-disabled-color:#929bad;
  --button-disabled-border:#929bad;
  --button-disabled-background:transparent;
}
.Button--white {
  --button-default-color:#2b2f34;
  --button-default-border:white;
  --button-default-background:white;
  --button-hover-color:#2b2f34;
  --button-hover-border:#d9d9d9;
  --button-hover-background:#d9d9d9;
  --button-focus-color:#2b2f34;
  --button-focus-border:#d9d9d9;
  --button-focus-background:#d9d9d9;
  --button-active-color:#2b2f34;
  --button-active-border:#cccccc;
  --button-active-background:#cccccc;
}
.Button--outlinedBlueActive {
  --button-default-color:#bdbdbd;
  --button-default-border:#929bad;
  --button-default-background:white;
  --button-hover-color:white;
  --button-hover-border:#225493;
  --button-hover-background:#225493;
  --button-focus-color:#bdbdbd;
  --button-focus-border:#929bad;
  --button-focus-background:#eeeeee;
  --button-active-color:white;
  --button-active-border:#297df8;
  --button-active-background:#297df8;
  --button-disabled-color:#d7d7d7;
  --button-disabled-border:#d7d7d7;
  --button-disabled-background:#fafafa;
  border-width: 1px;
}
.Button--outlinedBlackActive {
  --button-default-color:#929bad;
  --button-default-border:#929bad;
  --button-default-background:white;
  --button-hover-color:#72757b;
  --button-hover-border:#72757b;
  --button-hover-background:#edeff2;
  --button-focus-color:#929bad;
  --button-focus-border:#929bad;
  --button-focus-background:#eeeeee;
  --button-active-color:white;
  --button-active-border:#2b2f34;
  --button-active-background:#2b2f34;
  --button-disabled-color:#d7d7d7;
  --button-disabled-border:#d7d7d7;
  --button-disabled-background:#fafafa;
  border-width: 1px;
}
.Button--orange {
  --button-default-color:white;
  --button-default-border:#f35730;
  --button-default-background:#f35730;
  --button-hover-color:white;
  --button-hover-border:#54585c;
  --button-hover-background:#54585c;
  --button-focus-color:white;
  --button-focus-border:#2b2f34;
  --button-focus-background:#f35730;
  --button-active-color:white;
  --button-active-border:#297df8;
  --button-active-background:#297df8;
  --button-disabled-color:#929bad;
  --button-disabled-border:#929bad;
  --button-disabled-background:transparent;
}
.Button--whiteTransparent {
  --button-default-color:white;
  --button-default-border:white;
  --button-default-background:transparent;
  --button-hover-color:white;
  --button-hover-border:#225493;
  --button-hover-background:#225493;
  --button-focus-color:white;
  --button-focus-border:#225493;
  --button-focus-background:#225493;
  --button-active-color:white;
  --button-active-border:#297df8;
  --button-active-background:#297df8;
  --button-disabled-color:#929bad;
  --button-disabled-border:#929bad;
  --button-disabled-background:transparent;
  border-width: 1px;
}
.Button--orangeOutlined {
  --button-default-color:#f35730;
  --button-default-border:#f35730;
  --button-default-background:transparent;
  --button-hover-color:#54585c;
  --button-hover-border:#54585c;
  --button-hover-background:transparent;
  --button-focus-color:#f35730;
  --button-focus-border:#2b2f34;
  --button-focus-background:transparent;
  --button-active-color:#297df8;
  --button-active-border:#297df8;
  --button-active-background:transparent;
  --button-disabled-color:#929bad;
  --button-disabled-border:#929bad;
  --button-disabled-background:transparent;
  border-width: 1px;
}
.Button--pagination {
  min-width: 48px;
  height: 48px;
  padding: 0;
  font-size: 1rem;
  line-height: 48px;
}
.Button--close {
  min-width: unset;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  border-radius: 6px;
}
.Button--closeGray {
  --button-default-color:#2b2f34;
  --button-default-background:rgba(146, 155, 173, 0.5);
  --button-hover-color:white;
  --button-hover-background:#e18972;
  --button-focus-color:white;
  --button-focus-background:#e18972;
  --button-active-color:white;
  --button-active-background:#dd775d;
}
.Button--empty {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  height: auto;
}
.Button__icon + .Button__text, .Button__text + .Button__icon {
  margin-left: 14px;
}
.Button__icon {
  position: relative;
  flex-shrink: 0;
  width: 20px;
}
.Button__icon svg {
  width: 100%;
  fill: currentColor;
}
.Button__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
:root .Button__spinner {
  display: none;
}

.SiteModal__title {
  margin-bottom: 24px;
}
@media (max-width: 424px) {
  .SiteModal__title {
    margin-bottom: 16px;
  }
}

.SiteForm {
  --fields-gap-vertical: 32px;
  --fields-gap-horizontal: 32px;
  --cell-margin-vertical: calc(var(--fields-gap-vertical) / 2);
  --cell-margin-horizontal: calc(var(--fields-gap-horizontal) / 2);
  --form-default-color:#2b2f34;
  --form-default-background:white;
  --form-default-border:transparent;
  --form-default-option-color:#2b2f34;
  --form-default-option-background:white;
  --form-hover-color:#2b2f34;
  --form-hover-background:white;
  --form-hover-border:transparent;
  --form-hover-option-color:#2b2f34;
  --form-hover-option-background:#f2f2f2;
  --form-active-color:#2b2f34;
  --form-active-background:white;
  --form-active-border:#297df8;
  --form-active-option-color:#929bad;
  --form-active-option-background:white;
  --form-disabled-color:rgba(146, 155, 173, 0.5);
  --form-disabled-background:rgba(255, 255, 255, 0.5);
  --form-disabled-border:transparent;
}
@media (max-width: 424px) {
  .SiteForm {
    --fields-gap-vertical: 24px;
    --fields-gap-horizontal: 24px;
  }
}
.SiteForm--small {
  --fields-gap-vertical: 24px;
  --fields-gap-horizontal: 24px;
}
@media (max-width: 424px) {
  .SiteForm--small {
    --fields-gap-vertical: 24px;
    --fields-gap-horizontal: 24px;
  }
}
.SiteForm__title {
  margin-bottom: 32px;
  color: #2b2f34;
}
@media (max-width: 639px) {
  .SiteForm__title {
    margin-bottom: 24px;
  }
}
.SiteForm__subtitle {
  margin-bottom: 24px;
  color: #2b2f34;
}
@media (max-width: 639px) {
  .SiteForm__subtitle {
    margin-bottom: 16px;
  }
}
.SiteForm__grid + .SiteForm__subtitle {
  margin-top: 40px;
}
@media (max-width: 639px) {
  .SiteForm__grid + .SiteForm__subtitle {
    margin-top: 32px;
  }
}
.SiteForm__grid {
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--cell-margin-vertical)) calc(-1 * var(--cell-margin-horizontal));
}
.SiteForm__grid + .SiteForm__grid {
  padding-top: 32px;
}
@media (max-width: 999px) {
  .SiteForm__grid + .SiteForm__grid {
    padding-top: 24px;
  }
}
@media (max-width: 424px) {
  .SiteForm__grid + .SiteForm__grid {
    padding-top: 16px;
  }
}
.SiteForm__cell {
  position: relative;
  width: calc(100% - var(--fields-gap-horizontal));
  margin: var(--cell-margin-vertical) var(--cell-margin-horizontal);
}
@media (min-width: 640px) {
  .SiteForm__cell--col-1-2 {
    width: calc(50% - var(--fields-gap-horizontal));
  }
}
@media (min-width: 640px) {
  .SiteForm__cell--col-1-3 {
    width: calc(50% - var(--fields-gap-horizontal));
  }
}
@media (min-width: 900px) {
  .SiteForm__cell--col-1-3 {
    width: calc(33.3333% - var(--fields-gap-horizontal));
  }
}
html:root:root .SiteForm__select {
  background-position-x: calc(100% - 12px);
}
.SiteForm .vs--open + .SiteForm__label, .SiteForm .SiteForm__label--active, .SiteForm .SiteForm__input:not(:placeholder-shown) + .SiteForm__label, .SiteForm .SiteForm__input:focus + .SiteForm__label, .SiteForm .SiteForm__textarea:not(:placeholder-shown) + .SiteForm__label, .SiteForm .SiteForm__textarea:focus + .SiteForm__label {
  transform: scale(0.7) translateY(calc(58px / -2 + 0.875rem / 1.6));
  pointer-events: none;
}
.SiteForm__input:not(.SiteForm__input--showPlaceholder, .SiteForm__textarea--showPlaceholder)::placeholder, .SiteForm__textarea:not(.SiteForm__input--showPlaceholder, .SiteForm__textarea--showPlaceholder)::placeholder {
  opacity: 0;
  user-select: none;
}
.SiteForm__input:not(.SiteForm__input--showPlaceholder, .SiteForm__textarea--showPlaceholder):-ms-input-placeholder, .SiteForm__input:not(.SiteForm__input--showPlaceholder, .SiteForm__textarea--showPlaceholder)::-ms-input-placeholder, .SiteForm__textarea:not(.SiteForm__input--showPlaceholder, .SiteForm__textarea--showPlaceholder):-ms-input-placeholder, .SiteForm__textarea:not(.SiteForm__input--showPlaceholder, .SiteForm__textarea--showPlaceholder)::-ms-input-placeholder {
  opacity: 0;
  user-select: none;
}
:root .SiteForm__input--invalid, :root .SiteForm__textarea--invalid {
  color: #df1e1e;
  background-color: #f3d6d6;
  border-color: #f3d6d6;
}
:root .SiteForm__input--invalid:focus, :root .SiteForm__textarea--invalid:focus {
  color: var(--form-active-color);
  background-color: var(--form-active-background);
  border-color: var(--form-active-border);
}
:root .SiteForm__input--invalid + .SiteForm__label, :root .SiteForm__textarea--invalid + .SiteForm__label {
  color: #df1e1e;
}
.SiteForm__label {
  position: absolute;
  top: calc((58px - 0.875rem) / 2);
  left: 13px;
  border-radius: 3px;
  padding: 0 4px;
  color: rgba(43, 47, 52, 0.5);
  font-size: 0.875rem;
  line-height: 1;
  cursor: text;
  white-space: nowrap;
  user-select: none;
  transform-origin: top left;
  transition: 0.25s ease-in-out;
  transition-property: transform, color;
  pointer-events: none;
}
.vs--invalid + .SiteForm__label {
  color: #df1e1e;
}
.SiteForm__label--required::after {
  content: "*";
  color: #df1e1e;
}
.SiteForm__error {
  display: block;
  position: relative;
  margin-top: 4px;
  font-size: 0.875rem;
  color: #df1e1e;
}
.SiteForm__footer {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}
@media (max-width: 424px) {
  .SiteForm__footer {
    margin-top: 24px;
  }
}
.SiteForm__footer--left {
  align-items: start;
  text-align: left;
}
.SiteForm__footer--withAgreement {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 48px;
}
@media (max-width: 999px) {
  .SiteForm__footer--withAgreement {
    margin-top: 40px;
  }
}
@media (max-width: 424px) {
  .SiteForm__footer--withAgreement {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 32px;
  }
}
.SiteForm__footer--withAgreement .SiteForm__agreement {
  margin-left: 40px;
  font-size: 1rem;
}
@media (max-width: 639px) {
  .SiteForm__footer--withAgreement .SiteForm__agreement {
    margin-left: 32px;
    font-size: 0.875rem;
  }
}
@media (max-width: 424px) {
  .SiteForm__footer--withAgreement .SiteForm__agreement {
    margin-left: 0;
    margin-top: 16px;
  }
}
.SiteForm__agreement {
  font-size: 0.75rem;
  color: #72757b;
}
.SiteForm__agreement--bottomGap {
  margin-bottom: 32px;
}
@media (max-width: 424px) {
  .SiteForm__agreement--bottomGap {
    margin-bottom: 24px;
  }
}
.SiteForm__agreement a {
  color: inherit;
  text-decoration: underline;
}
.SiteForm__agreement a:hover, .SiteForm__agreement a:focus, .SiteForm__agreement a:active {
  color: #df1e1e;
  text-decoration: underline;
}

.Checkbox,
.Radio {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}
.Checkbox__control,
.Radio__control {
  flex-shrink: 0;
}
.Checkbox__label,
.Radio__label {
  margin-left: 12px;
  font-size: 0.875rem;
  line-height: 1.8;
  color: black;
  transition: color 0.2s ease-in-out;
}

.Checkbox--invalid .Checkbox__control, .Checkbox--invalid .Checkbox__control:hover:not(:checked):not(:focus) {
  background-color: rgba(223, 30, 30, 0.5);
}
:root .Checkbox__control {
  size: 20px;
  border-radius: 5px;
}
:root .Checkbox__control:hover, :root .Checkbox__control:focus {
  background-color: rgba(0, 156, 91, 0.5);
}
.Checkbox__label {
  line-height: 20px;
}

.Loader {
  position: relative;
  width: 100%;
  height: 200px;
  color: #f35730;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Loader--button {
  height: 24px;
  color: inherit;
}
.Loader--button .Loader__spinner {
  width: 24px;
}
.Loader__spinner {
  width: 50px;
  animation: 2s linear infinite loader-rotate;
}
.Loader__circle {
  animation: 1.4s ease-in-out infinite both loader-animation;
  display: block;
  fill: transparent;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 200;
  stroke-width: 8px;
  transform-origin: 50% 50%;
}

@keyframes loader-rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes loader-animation {
  0%, 25% {
    stroke-dashoffset: 150;
    transform: rotate(0);
  }
  50%, 75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }
  100% {
    stroke-dashoffset: 150;
    transform: rotate(360deg);
  }
}
.Layout {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
.Layout__header {
  flex-shrink: 0;
}
.Layout__body {
  flex-grow: 1;
}
.Layout__body--centerVertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.Layout__footer {
  flex-shrink: 0;
}
.Layout__section {
  padding-y: 48px 80px;
}
@media (max-width: 1199px) {
  .Layout__section {
    padding-y: 40px 72px;
  }
}
@media (max-width: 999px) {
  .Layout__section {
    padding-y: 32px 64px;
  }
}
@media (max-width: 899px) {
  .Layout__section {
    padding-y: 24px 56px;
  }
}
@media (max-width: 639px) {
  .Layout__section {
    padding-y: 24px 40px;
  }
}
@media (max-width: 424px) {
  .Layout__section {
    padding-y: 24px 32px;
  }
}
.Layout__section--largeTopGap {
  padding-top: 80px;
}
@media (max-width: 1199px) {
  .Layout__section--largeTopGap {
    padding-top: 72px;
  }
}
@media (max-width: 999px) {
  .Layout__section--largeTopGap {
    padding-top: 64px;
  }
}
@media (max-width: 899px) {
  .Layout__section--largeTopGap {
    padding-top: 56px;
  }
}
@media (max-width: 639px) {
  .Layout__section--largeTopGap {
    padding-top: 40px;
  }
}
@media (max-width: 424px) {
  .Layout__section--largeTopGap {
    padding-top: 32px;
  }
}
.Layout__section--smallGap {
  padding-y: 44px;
}
@media (max-width: 1199px) {
  .Layout__section--smallGap {
    padding-y: 40px;
  }
}
@media (max-width: 999px) {
  .Layout__section--smallGap {
    padding-y: 36px;
  }
}
@media (max-width: 899px) {
  .Layout__section--smallGap {
    padding-y: 32px;
  }
}
@media (max-width: 639px) {
  .Layout__section--smallGap {
    padding-y: 28px;
  }
}
@media (max-width: 424px) {
  .Layout__section--smallGap {
    padding-y: 24px;
  }
}
.Layout__section--smallTopGap {
  padding-top: 44px;
}
@media (max-width: 1199px) {
  .Layout__section--smallTopGap {
    padding-top: 40px;
  }
}
@media (max-width: 999px) {
  .Layout__section--smallTopGap {
    padding-top: 36px;
  }
}
@media (max-width: 899px) {
  .Layout__section--smallTopGap {
    padding-top: 32px;
  }
}
@media (max-width: 639px) {
  .Layout__section--smallTopGap {
    padding-top: 28px;
  }
}
@media (max-width: 424px) {
  .Layout__section--smallTopGap {
    padding-top: 24px;
  }
}
.Layout__section--smallBottomGap {
  padding-bottom: 44px;
}
@media (max-width: 1199px) {
  .Layout__section--smallBottomGap {
    padding-bottom: 40px;
  }
}
@media (max-width: 999px) {
  .Layout__section--smallBottomGap {
    padding-bottom: 36px;
  }
}
@media (max-width: 899px) {
  .Layout__section--smallBottomGap {
    padding-bottom: 32px;
  }
}
@media (max-width: 639px) {
  .Layout__section--smallBottomGap {
    padding-bottom: 28px;
  }
}
@media (max-width: 424px) {
  .Layout__section--smallBottomGap {
    padding-bottom: 24px;
  }
}
.Layout__section--noBottomGap {
  padding-bottom: 0;
}
.Layout__section--noTopGap {
  padding-top: 0;
}
.Layout__section--gray {
  background-color: #edeff2;
}
@media (min-width: 900px) {
  .Layout__section--halfGray {
    background: linear-gradient(180deg, white 60%, #edeff2 60%);
  }
}
@media (max-width: 899px) {
  .Layout__section--littleGrayMobile {
    background: linear-gradient(180deg, white 70%, #edeff2 70%);
  }
}
@media (max-width: 424px) {
  .Layout__section--littleGrayMobile {
    background: linear-gradient(180deg, white 80%, #edeff2 80%);
  }
}
@media (max-width: 899px) {
  .Layout__section--mostlyGrayMobile {
    background: linear-gradient(180deg, white 40%, #edeff2 40%);
  }
}
@media (max-width: 424px) {
  .Layout__section--mostlyGrayMobile {
    background: linear-gradient(180deg, white 25%, #edeff2 25%);
  }
}
.Layout__title {
  margin-bottom: 48px;
  color: #2b2f34;
}
@media (max-width: 999px) {
  .Layout__title {
    margin-bottom: 40px;
  }
}
@media (max-width: 639px) {
  .Layout__title {
    margin-bottom: 32px;
  }
}
@media (max-width: 424px) {
  .Layout__title {
    margin-bottom: 24px;
  }
}
.Layout__title--centered {
  text-align: center;
}
.Layout__title--xlarge {
  margin-bottom: 40px;
}
@media (max-width: 999px) {
  .Layout__title--xlarge {
    margin-bottom: 32px;
  }
}
@media (max-width: 639px) {
  .Layout__title--xlarge {
    margin-bottom: 24px;
  }
}
@media (max-width: 424px) {
  .Layout__title--xlarge {
    margin-bottom: 16px;
  }
}
.Layout__subtitle {
  font-size: 1.125rem;
  color: #72757b;
  text-align: center;
  margin-top: -32px;
  margin-bottom: 48px;
}
@media (max-width: 999px) {
  .Layout__subtitle {
    margin-top: -24px;
    margin-bottom: 40px;
  }
}
@media (max-width: 639px) {
  .Layout__subtitle {
    margin-top: -24px;
    margin-bottom: 32px;
    font-size: 1rem;
  }
}
@media (max-width: 424px) {
  .Layout__subtitle {
    margin-top: -16px;
  }
}
.Layout__container--smallest {
  max-width: 739px;
}

.MobileBurgerContainer {
  position: fixed;
  z-index: 1002;
  left: 0;
  top: calc(var(--header-border-box-height) / 2);
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
  pointer-events: none;
}

.MobileBurger {
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-right: 3px;
  padding: 16px;
  border: none;
  outline: none;
  transition: transform 0.2s ease-in-out;
  background: transparent;
  pointer-events: all;
}
@media (hover: hover) and (pointer: fine) {
  .MobileBurger:hover::before, .MobileBurger:focus::before, .MobileBurger:active::before, .MobileBurger[data-open=true]::before {
    opacity: 1;
    transform: scale(0.9);
  }
  .MobileBurger:hover::after, .MobileBurger:focus::after, .MobileBurger:active::after, .MobileBurger[data-open=true]::after {
    opacity: 0.5;
    transform: scale(1.1);
  }
}
.MobileBurger::before, .MobileBurger::after {
  content: "";
  display: block;
  background-color: #f35730;
  border-radius: 50%;
  transition: 0.25s ease-in-out;
  transition-property: transform, opacity;
}
.MobileBurger::before {
  transform: scale(1.1);
  opacity: 0.5;
}
.MobileBurger::after {
  transform: scale(0.9);
}
.MobileBurger__outer {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 24px;
  height: 17px;
}
.MobileBurger__inner {
  top: 6px;
  display: block;
}
.MobileBurger__inner, .MobileBurger__inner::after {
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 2px;
  transition: background-color 0.25s ease-in-out, transform 0.15s ease, top 75ms ease 0.12s;
  background-color: white;
}
.MobileBurger__inner::after {
  content: "";
  display: block;
}
.MobileBurger__inner::after {
  top: auto;
  bottom: -9px;
  transition: background-color 0.25s ease-in-out, bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.MobileBurger[data-open=true] .MobileBurger__inner {
  top: 11px;
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg);
}
.MobileBurger[data-open=true] .MobileBurger__inner::after {
  bottom: 0;
  transition: background-color 0.25s ease-in-out, bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transform: rotate(-90deg);
}

.OffCanvas {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
}
@media (max-width: 424px) {
  .OffCanvas {
    width: 100vw;
  }
}
.OffCanvas__header {
  display: flex;
  flex-shrink: 0;
  height: var(--header-border-box-height);
}
.OffCanvas__footer {
  margin-top: auto;
  padding-y: 32px;
}
.OffCanvas__button {
  width: 100%;
}
.OffCanvas__container {
  width: 100%;
  padding-x: 40px;
}
@media (max-width: 899px) {
  .OffCanvas__container {
    padding-x: 32px;
  }
}
@media (max-width: 639px) {
  .OffCanvas__container {
    padding-x: 16px;
  }
}
.OffCanvas__icons {
  display: flex;
  align-items: center;
  height: 100%;
}

:root {
  --header-vertical-padding: 32px;
  --header-content-box-height: 68px;
  --header-border-box-height: calc(
      var(--header-content-box-height) + var(--header-vertical-padding) * 2
  );
}
@media (max-width: 1199px) {
  :root {
    --header-content-box-height: 64px;
  }
}
@media (max-width: 639px) {
  :root {
    --header-vertical-padding: 16px;
  }
}

.SiteHeader {
  padding-y: var(--header-vertical-padding);
}
.SiteHeader__flex {
  display: flex;
  align-items: center;
}
.SiteHeader__logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-x: -35px 40px;
  min-height: 68px;
}
@media (max-width: 1439px) {
  .SiteHeader__logo {
    margin-x: 0;
  }
}
@media (max-width: 1199px) {
  .SiteHeader__logo {
    margin-x: 0 auto;
    min-height: 64px;
  }
}
@media (max-width: 369px) {
  .SiteHeader__logo {
    align-items: center;
  }
}
.SiteHeader__logoImage {
  width: 240px;
}
@media (max-width: 1439px) and (min-width: 1200px) {
  .SiteHeader__logoImage {
    width: 200px;
  }
}
@media (max-width: 424px) {
  .SiteHeader__logoImage {
    height: 7vw;
  }
}
.SiteHeader__logoText {
  margin-top: 4px;
  font-size: 0.8125rem;
  line-height: 1.46;
  color: #72757b;
}
@media (max-width: 424px) {
  .SiteHeader__logoText {
    font-size: 0.75rem;
  }
}
@media (max-width: 369px) {
  .SiteHeader__logoText {
    font-size: 0.6875rem;
  }
}
.SiteHeader__nav {
  margin-right: auto;
}
@media (max-width: 1439px) {
  .SiteHeader__nav {
    min-width: 500px;
    margin-x: auto;
  }
}
@media (max-width: 1199px) {
  .SiteHeader__nav {
    display: none;
  }
}
.SiteHeader__icons {
  display: flex;
  margin-right: 40px;
}
@media (max-width: 1439px) and (min-width: 1200px) {
  .SiteHeader__icons {
    margin-right: 32px;
  }
}
@media (max-width: 1199px) {
  .SiteHeader__icons {
    display: none;
  }
}
.SiteHeader__phone:not(:last-child) {
  margin-right: 32px;
}
@media (max-width: 1439px) {
  .SiteHeader__phone:not(:last-child) {
    margin-right: 24px;
  }
}
.SiteHeader__button {
  margin-right: 0;
}
@media (max-width: 1439px) and (min-width: 1200px) {
  .SiteHeader__button {
    padding: 0 24px;
    height: 54px;
    min-width: unset;
  }
}
@media (max-width: 1199px) {
  .SiteHeader__button {
    margin-right: 104px;
  }
}
@media (max-width: 899px) {
  .SiteHeader__button {
    display: none;
  }
}
.SiteHeader__mobile {
  display: none;
}
@media (max-width: 1199px) {
  .SiteHeader__mobile {
    display: block;
  }
}

.SiteFooter {
  background-color: #2b2f34;
  padding-top: 48px;
}
@media (max-width: 899px) {
  .SiteFooter {
    padding-top: 32px;
  }
}
.SiteFooter__grid {
  display: grid;
  grid-template: auto/minmax(186px, max-content) minmax(min-content, 186px);
  grid-gap: 54px;
}
@media (max-width: 899px) {
  .SiteFooter__grid {
    margin-bottom: 24px;
    grid-template-columns: 1fr 1fr;
    max-width: 426px;
    grid-gap: 24px;
  }
}
@media (max-width: 424px) {
  .SiteFooter__grid {
    grid-gap: 16px;
  }
}
@media (max-width: 369px) {
  .SiteFooter__grid {
    grid-template: auto auto/1fr;
  }
}
.SiteFooter__flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 24px;
}
@media (max-width: 899px) {
  .SiteFooter__flex--top {
    flex-direction: column;
  }
}
.SiteFooter__flex--bottom {
  align-items: center;
  padding-top: 24px;
  border-top: 1px solid rgba(146, 155, 173, 0.25);
}
@media (max-width: 639px) {
  .SiteFooter__flex--bottom {
    flex-direction: column;
    align-items: stretch;
  }
}
.SiteFooter__logo {
  display: flex;
  align-items: center;
  min-height: 68px;
  margin-bottom: 12px;
}
@media (max-width: 1199px) {
  .SiteFooter__logo {
    min-height: 64px;
  }
}
@media (max-width: 639px) {
  .SiteFooter__logo {
    flex-direction: column;
    align-items: flex-start;
  }
}
.SiteFooter__logoImage {
  flex-shrink: 0;
  width: 244px;
}
.SiteFooter__logoText {
  margin-left: 24px;
  font-size: 1.125rem;
  line-height: 1.8;
  color: #929bad;
}
@media (max-width: 999px) {
  .SiteFooter__logoText {
    margin-left: 20px;
    font-size: 1rem;
  }
}
@media (max-width: 639px) {
  .SiteFooter__logoText {
    margin: 4px 0 0;
    font-size: 0.875rem;
  }
}
.SiteFooter__buttons {
  display: flex;
  flex-direction: column;
}
@media (max-width: 899px) {
  .SiteFooter__buttons {
    flex-direction: row;
    max-width: 426px;
    width: 100%;
  }
}
@media (max-width: 424px) {
  .SiteFooter__buttons {
    flex-direction: column;
  }
}
@media (max-width: 899px) {
  .SiteFooter__getQuote, .SiteFooter__sendMessage {
    width: 100%;
  }
}
.SiteFooter__getQuote:not(:last-child) {
  margin-bottom: 24px;
}
@media (max-width: 899px) {
  .SiteFooter__getQuote:not(:last-child) {
    margin-bottom: 0;
    margin-right: 24px;
  }
}
@media (max-width: 424px) {
  .SiteFooter__getQuote:not(:last-child) {
    margin-bottom: 24px;
    margin-right: 0;
  }
}
.SiteFooter__contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 424px) {
  .SiteFooter__contacts {
    max-width: 186px;
  }
}
.SiteFooter__contactsLink {
  color: white;
  margin-bottom: 24px;
}
.SiteFooter__contactsLink:hover, .SiteFooter__contactsLink:focus, .SiteFooter__contactsLink:active {
  color: #f35730;
}
.SiteFooter__contactsElement {
  color: #929bad;
  font-size: 0.875rem;
  line-height: 1.8;
}
.SiteFooter__contactsElement:not(:last-child) {
  margin-bottom: 16px;
}
.SiteFooter__contactsElement--link {
  outline: none;
}
.SiteFooter__contactsElement--link:hover, .SiteFooter__contactsElement--link:focus, .SiteFooter__contactsElement--link:active {
  color: #f35730;
}
.SiteFooter__phone:not(:last-child) {
  margin-bottom: 8px;
}
.SiteFooter__copyright {
  color: #929bad;
}
@media (max-width: 639px) {
  .SiteFooter__copyright {
    margin-bottom: 16px;
  }
}
.SiteFooter__feipLogo {
  color: #929bad;
  transition: color 0.25s ease-in-out;
}
@media (max-width: 639px) {
  .SiteFooter__feipLogo {
    margin-left: auto;
  }
}
.SiteFooter__feipLogo:hover, .SiteFooter__feipLogo:focus, .SiteFooter__feipLogo:active {
  color: #f35730;
}
.SiteFooter__feipLogo svg {
  transition: fill 0.25s ease-in-out;
  fill: #929bad;
}
.SiteFooter__feipLogo svg:hover, .SiteFooter__feipLogo svg:focus, .SiteFooter__feipLogo svg:active {
  fill: #f35730;
}

.SiteNav__item, .SiteFooter__contactsLink {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.15px;
  outline: none;
}

.SiteNav {
  display: flex;
}
.SiteNav--modeHeader .SiteNav__item, .SiteNav--modeOffcanvas .SiteNav__item {
  color: #929bad;
}
.SiteNav--modeHeader .SiteNav__item--active, .SiteNav--modeOffcanvas .SiteNav__item--active {
  color: #f35730;
}
.SiteNav--modeFooter, .SiteNav--modeOffcanvas {
  flex-direction: column;
}
.SiteNav--modeFooter .SiteNav__listItem {
  margin: 0 0 24px;
}
.SiteNav--modeFooter .SiteNav__item {
  color: white;
}
.SiteNav--modeFooter .SiteNav__item--active {
  color: #f35730;
}
.SiteNav--modeOffcanvas .SiteNav__listItem {
  margin: 0;
  border-bottom: 1px solid rgba(43, 47, 52, 0.1);
}
.SiteNav--modeOffcanvas .SiteNav__item {
  display: block;
  padding: 16px 40px;
  font-size: 1.25rem;
  -webkit-tap-highlight-color: rgba(146, 155, 173, 0.1);
}
@media (max-width: 899px) {
  .SiteNav--modeOffcanvas .SiteNav__item {
    padding-x: 32px;
  }
}
@media (max-width: 639px) {
  .SiteNav--modeOffcanvas .SiteNav__item {
    padding-x: 16px;
  }
}
.SiteNav__listItem {
  margin: 0 24px 0 0;
}
@media (max-width: 1439px) {
  .SiteNav__listItem {
    margin-right: 16px;
  }
}
.SiteNav__listItem:last-child {
  margin: 0;
}
.SiteNav__item--link:hover, .SiteNav__item--link:focus, .SiteNav__item--link:active {
  color: #f35730;
}

.CookiesMessage {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 164px;
  padding: 32px 40px;
  background-color: white;
  box-shadow: none;
  transform: translateY(100%);
  z-index: 10000;
  transition-property: transform, box-shadow;
  transition: 0.25s ease-in-out;
}
@media (max-width: 899px) {
  .CookiesMessage {
    padding: 32px 32px;
  }
}
@media (max-width: 639px) {
  .CookiesMessage {
    height: fit-content;
    padding: 32px 16px;
  }
}
.CookiesMessage--shown {
  box-shadow: 10px 0 10px 4px rgba(0, 0, 0, 0.15);
  transform: translateY(0);
}
.CookiesMessage__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 992px;
}
@media (max-width: 639px) {
  .CookiesMessage__container {
    flex-direction: column;
    align-items: flex-start;
  }
}
.CookiesMessage__contentBlock {
  display: flex;
  align-items: flex-start;
  margin-right: 80px;
}
@media (max-width: 999px) {
  .CookiesMessage__contentBlock {
    margin-right: 64px;
  }
}
@media (max-width: 639px) {
  .CookiesMessage__contentBlock {
    margin-right: 0;
    margin-bottom: 24px;
  }
}
.CookiesMessage__icon {
  flex-shrink: 0;
  size: 80px;
}
@media (max-width: 999px) {
  .CookiesMessage__icon {
    size: 64px;
  }
}
.CookiesMessage__textBlock {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}
.CookiesMessage__heading {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 8px;
  color: #323540;
}
@media (max-width: 1199px) {
  .CookiesMessage__heading {
    font-size: 1.5rem;
  }
}
@media (max-width: 999px) {
  .CookiesMessage__heading {
    font-size: 1.25rem;
  }
}
.CookiesMessage__description {
  font-size: 1rem;
  line-height: 1.5;
  color: #999a9f;
}
@media (max-width: 999px) {
  .CookiesMessage__description {
    font-size: 0.875rem;
  }
}
.CookiesMessage__description a {
  text-decoration: underline;
  color: #297df8;
}
.CookiesMessage__description a:hover, .CookiesMessage__description a:focus, .CookiesMessage__description a:active {
  text-decoration: underline;
  color: #f35730;
}
.CookiesMessage__acceptButton {
  min-width: 160px;
}
@media (max-width: 639px) {
  .CookiesMessage__acceptButton {
    min-width: 128px;
    height: 48px;
    margin-left: 88px;
    padding: 16px;
  }
}
@media (max-width: 369px) {
  .CookiesMessage__acceptButton {
    width: fit-content;
  }
}

.v-select {
  position: relative;
  font-family: inherit;
}

.v-select,
.v-select * {
  box-sizing: border-box;
}

/* KeyFrames */
@-webkit-keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Dropdown Default Transition */
.vs__fade-enter-active,
.vs__fade-leave-active {
  pointer-events: none;
  transition: opacity 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.vs__fade-enter,
.vs__fade-leave-to {
  opacity: 0;
}

/** Component States */
/*
 * Disabled
 *
 * When the component is disabled, all interaction
 * should be prevented. Here we modify the bg color,
 * and change the cursor displayed on the interactive
 * components.
 */
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator {
  cursor: not-allowed;
  background-color: #f8f8f8;
}

/*
 *  RTL - Right to Left Support
 *
 *  Because we're using a flexbox layout, the `dir="rtl"`
 *  HTML attribute does most of the work for us by
 *  rearranging the child elements visually.
 */
.v-select[dir=rtl] .vs__actions {
  padding: 0 3px 0 6px;
}
.v-select[dir=rtl] .vs__clear {
  margin-left: 6px;
  margin-right: 0;
}
.v-select[dir=rtl] .vs__deselect {
  margin-left: 0;
  margin-right: 2px;
}
.v-select[dir=rtl] .vs__dropdown-menu {
  text-align: right;
}

/**
    Dropdown Toggle

    The dropdown toggle is the primary wrapper of the component. It
    has two direct descendants: .vs__selected-options, and .vs__actions.

    .vs__selected-options holds the .vs__selected's as well as the
    main search input.

    .vs__actions holds the clear button and dropdown toggle.
 */
.vs__dropdown-toggle {
  appearance: none;
  display: flex;
  padding: 0 0 4px 0;
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  white-space: normal;
}

.vs__selected-options {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0 2px;
  position: relative;
}

.vs__actions {
  display: flex;
  align-items: center;
  padding: 4px 6px 0 3px;
}

/* Dropdown Toggle States */
.vs--searchable .vs__dropdown-toggle {
  cursor: text;
}

.vs--unsearchable .vs__dropdown-toggle {
  cursor: pointer;
}

.vs--open .vs__dropdown-toggle {
  border-bottom-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
  transform: scale(1);
  transition: transform 150ms cubic-bezier(1, -0.115, 0.975, 0.855);
  transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
}

.vs--open .vs__open-indicator {
  transform: rotate(180deg) scale(1);
}

.vs--loading .vs__open-indicator {
  opacity: 0;
}

/* Clear Button */
.vs__clear {
  fill: rgba(60, 60, 60, 0.5);
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 8px;
}

/* Dropdown Menu */
.vs__dropdown-menu {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  z-index: 1000;
  padding: 5px 0;
  margin: 0;
  width: 100%;
  max-height: 350px;
  min-width: 160px;
  overflow-y: auto;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-top-style: none;
  border-radius: 0 0 4px 4px;
  text-align: left;
  list-style: none;
  background: #fff;
}

.vs__no-options {
  text-align: center;
}

/* List Items */
.vs__dropdown-option {
  line-height: 1.42857143;
  /* Normalize line height */
  display: block;
  padding: 3px 20px;
  clear: both;
  color: #333;
  /* Overrides most CSS frameworks */
  white-space: nowrap;
}
.vs__dropdown-option:hover {
  cursor: pointer;
}

.vs__dropdown-option--highlight {
  background: #5897fb;
  color: #fff;
}

.vs__dropdown-option--disabled {
  background: inherit;
  color: rgba(60, 60, 60, 0.5);
}
.vs__dropdown-option--disabled:hover {
  cursor: inherit;
}

/* Selected Tags */
.vs__selected {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  color: #333;
  line-height: 1.4;
  margin: 4px 2px 0px 2px;
  padding: 0 0.25em;
  z-index: 0;
}

.vs__deselect {
  display: inline-flex;
  appearance: none;
  margin-left: 4px;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  fill: rgba(60, 60, 60, 0.5);
  text-shadow: 0 1px 0 #fff;
}

/* States */
.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
}
.vs--single.vs--open .vs__selected {
  position: absolute;
  opacity: 0.4;
}
.vs--single.vs--searching .vs__selected {
  display: none;
}

/* Search Input */
/**
 * Super weird bug... If this declaration is grouped
 * below, the cancel button will still appear in chrome.
 * If it's up here on it's own, it'll hide it.
 */
.vs__search::-webkit-search-cancel-button {
  display: none;
}

.vs__search::-webkit-search-decoration,
.vs__search::-webkit-search-results-button,
.vs__search::-webkit-search-results-decoration,
.vs__search::-ms-clear {
  display: none;
}

.vs__search,
.vs__search:focus {
  appearance: none;
  line-height: 1.4;
  font-size: 1em;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  margin: 4px 0 0 0;
  padding: 0 7px;
  background: none;
  box-shadow: none;
  width: 0;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1;
}

.vs__search::placeholder {
  color: inherit;
}

/**
    States
 */
.vs--unsearchable .vs__search {
  opacity: 1;
}
.vs--unsearchable:not(.vs--disabled) .vs__search:hover {
  cursor: pointer;
}

.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 0.2;
}

/* Loading Spinner */
.vs__spinner {
  align-self: center;
  opacity: 0;
  font-size: 5px;
  text-indent: -9999em;
  overflow: hidden;
  border-top: 0.9em solid rgba(100, 100, 100, 0.1);
  border-right: 0.9em solid rgba(100, 100, 100, 0.1);
  border-bottom: 0.9em solid rgba(100, 100, 100, 0.1);
  border-left: 0.9em solid rgba(60, 60, 60, 0.45);
  transform: translateZ(0);
  animation: vSelectSpinner 1.1s infinite linear;
  transition: opacity 0.1s;
}

.vs__spinner,
.vs__spinner:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

/* Loading Spinner States */
.vs--loading .vs__spinner {
  opacity: 1;
}

/*! nouislider - 14.7.0 - 4/6/2021 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}

.noUi-connect {
  height: 100%;
  width: 100%;
}

.noUi-origin {
  height: 10%;
  width: 10%;
}

/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  top: -17px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3FB8AF;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}

/* ========================================================================
   Component: Spinner
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
/* SVG
 ========================================================================== */
.uk-spinner > * {
  animation: uk-spinner-rotate 1.4s linear infinite;
}

@keyframes uk-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
/*
 * Circle
 */
.uk-spinner > * > * {
  stroke-dasharray: 88px;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: uk-spinner-dash 1.4s ease-in-out infinite;
  stroke-width: 1;
  stroke-linecap: round;
}

@keyframes uk-spinner-dash {
  0% {
    stroke-dashoffset: 88px;
  }
  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg);
  }
}
.v-select.vs--single .vs__selected {
  padding: 13px 0 0;
  margin: 0;
  border: none;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.v-select.vs--single .vs__selected-options {
  margin: 0 24px 0 14px;
  flex-wrap: nowrap;
  max-width: calc(100% - 36px);
}
.v-select.vs--single .vs__search,
.v-select.vs--single .vs__search:focus {
  margin: 0;
  border: none;
  padding: 13px 0 0;
}
.v-select.vs--single.vs--open .vs__selected {
  opacity: 1;
  color: var(--form-active-option-color);
}
.v-select:not(.vs--single) .vs__selected {
  background-color: #edeff2;
  border: none;
  font-size: 0.8125rem;
  padding: 2px 6px;
  margin: 3px 4px;
}
.v-select:not(.vs--single) .vs__selected-options {
  padding: 20px 10px 6px;
}
.v-select:hover .vs__dropdown-toggle {
  color: var(--form-hover-color);
  border-color: var(--form-hover-border);
  background-color: var(--form-hover-background);
}
.v-select.vs--invalid .vs__dropdown-toggle {
  color: #df1e1e;
  background-color: #f3d6d6;
  border-color: #f3d6d6;
}
.v-select.vs--open .vs__open-indicator {
  transform: scaleY(-1);
}
.v-select.vs--open .vs__dropdown-toggle {
  color: var(--form-active-color);
  border-color: var(--form-active-border);
  background-color: var(--form-active-background);
  border-radius: 5px 5px 0 0;
}
.v-select.vs--has-options .vs__no-options {
  display: none;
}
.v-select.vs--hide-actions .vs__actions {
  display: none;
}
.v-select .vs__open-indicator {
  display: block;
  width: 16px;
  height: 9px;
  background-image: url("/themes/frontend//assets/icons/arrow-dropdown.svg");
  transform-origin: center;
}
.v-select .vs__actions {
  top: 50%;
  transform: translateY(-50%);
  margin-top: -2px;
}
.v-select .vs__search {
  color: var(--form-default-olor);
  line-height: unset;
}
.v-select .vs__search::placeholder {
  color: transparent;
  font-size: 0.875rem;
}
.v-select .vs__dropdown-toggle,
.v-select .vs__dropdown-menu {
  color: var(--form-default-color);
  font-size: 0.875rem;
}
.v-select .vs__selected {
  color: var(--form-default-color);
  transition: opacity 0.25s ease-in-out;
}
.v-select .vs__dropdown-toggle {
  min-height: 58px;
  border-radius: 5px;
  padding: 0;
  transition-property: background-color, border-color, color;
  transition: 0.25s ease-in-out;
  border: 2px var(--form-default-border) solid;
  background-color: var(--form-default-background);
}
.v-select .vs__dropdown-menu {
  top: 100%;
  border: var(--form-default-border);
  padding: 0;
  z-index: 9;
  max-height: 160px;
  border-radius: 0 0 5px 5px;
  min-width: 80px;
  background-color: var(--form-default-option-background);
}
.v-select .vs__dropdown-option {
  padding: 10px 13px;
  white-space: normal;
  background-color: var(--form-default-option-background);
  color: var(--form-default-option-color);
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
}
.v-select .vs__dropdown-option--highlight {
  background-color: var(--form-hover-option-background);
  color: var(--form-hover-option-color);
}
.v-select .vs__dropdown-option--disabled {
  opacity: 0.5;
}
.v-select .vs__dropdown-option--selected {
  background-color: var(--form-active-option-background);
  color: var(--form-active-option-color);
  cursor: default;
}
.v-select .vs__selected-options {
  align-items: center;
  padding: 0;
}
.v-select .vs__actions {
  position: absolute;
  right: 14px;
  padding-right: 0;
}
.v-select .vs__no-options {
  padding: 10px 12px;
  background-color: var(--form-default-option-background);
}
.v-select .vs__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
}
.v-select .vs__loader-icon {
  width: 30px;
  height: 30px;
}
.v-select.vs--disabled:hover .vs__dropdown-toggle {
  background-color: var(--form-disabled-background);
  color: var(--form-disabled-color);
  border-color: var(--form-disabled-border);
}
.v-select.vs--disabled .vs__dropdown-toggle {
  color: var(--form-disabled-color);
  background-color: var(--form-disabled-background);
  border-color: var(--form-disabled-border);
}
.v-select.vs--disabled .vs__search {
  background-color: transparent;
}
.v-select.vs--disabled .vs__actions,
.v-select.vs--disabled .vs__open-indicator {
  background-color: transparent;
  opacity: 0.5;
}
.v-select.vs--disabled .vs__dropdown-toggle,
.v-select.vs--disabled .vs__search,
.v-select.vs--disabled .vs__actions,
.v-select.vs--disabled .vs__open-indicator,
.v-select.vs--disabled .vs__selected-options {
  cursor: default;
}
.v-select.vs--disabled .vs__dropdown-toggle:hover,
.v-select.vs--disabled .vs__search:hover,
.v-select.vs--disabled .vs__actions:hover,
.v-select.vs--disabled .vs__open-indicator:hover,
.v-select.vs--disabled .vs__selected-options:hover {
  cursor: default;
}

.noUi-target {
  box-shadow: none;
  border: none;
  background-color: transparent;
}

.noUi-connect {
  background-color: #f35730;
}

.noUi-handle {
  box-shadow: none;
  outline: none;
  width: 16px;
  height: 16px;
  top: -8px;
  right: -8px;
  border-radius: 50%;
  background-color: white;
  border: 2px #f35730 solid;
  transition: background-color 0.25s ease-in-out;
  cursor: pointer;
}
.noUi-handle:hover, .noUi-handle:active {
  background-color: red;
}
.noUi-handle::after, .noUi-handle::before {
  content: unset;
}

.noUi-horizontal {
  height: 2px;
}
.noUi-horizontal .noUi-handle {
  width: 16px;
  height: 16px;
  right: -8px;
  top: -8px;
}

.CardsList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 48px;
  grid-row-gap: 40px;
}
@media (max-width: 1199px) {
  .CardsList {
    grid-column-gap: 40px;
    grid-row-gap: 36px;
  }
}
@media (max-width: 999px) {
  .CardsList {
    grid-column-gap: 36px;
    grid-row-gap: 24px;
  }
}
@media (max-width: 899px) {
  .CardsList {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
}
@media (max-width: 639px) {
  .CardsList {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 424px) {
  .CardsList {
    grid-template-columns: 1fr;
  }
}
.CardsList--withFilters {
  margin-top: 40px;
}
@media (max-width: 899px) {
  .CardsList--withFilters {
    margin-top: 32px;
  }
}

.BenefitsList__title {
  margin-bottom: 48px;
  text-align: center;
  color: #2b2f34;
}
@media (max-width: 999px) {
  .BenefitsList__title {
    margin-bottom: 40px;
  }
}
@media (max-width: 424px) {
  .BenefitsList__title {
    margin-bottom: 32px;
  }
}
.BenefitsList__list {
  display: flex;
  margin: -20px -30px;
  padding: 0;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 999px) {
  .BenefitsList__list {
    display: flex;
    margin: -20px -24px;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media (max-width: 424px) {
  .BenefitsList__list {
    display: flex;
    margin: -16px -24px;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.BenefitsList__item {
  padding: 20px 30px;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.8;
}
@media (max-width: 999px) {
  .BenefitsList__item {
    padding: 20px 24px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (max-width: 899px) {
  .BenefitsList__item {
    padding: 20px 24px;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 424px) {
  .BenefitsList__item {
    padding: 16px 24px;
    flex: 0 0 100%;
    max-width: 100%;
    flex-direction: row;
    text-align: left;
  }
}
@media (max-width: 424px) {
  .BenefitsList__itemContent {
    margin-left: 16px;
  }
}
.BenefitsList__image {
  margin-bottom: 16px;
  size: 80px;
  flex-shrink: 0;
}
@media (max-width: 999px) {
  .BenefitsList__image {
    margin-bottom: 14px;
  }
}
@media (max-width: 639px) {
  .BenefitsList__image {
    margin-bottom: 12px;
  }
}
@media (max-width: 424px) {
  .BenefitsList__image {
    margin-bottom: 0;
    align-self: flex-start;
    margin-top: 4px;
  }
}
.BenefitsList__itemHeading {
  margin-bottom: 12px;
  font-size: 1.25rem;
  color: #2b2f34;
}
@media (max-width: 999px) {
  .BenefitsList__itemHeading {
    margin-bottom: 10px;
    font-size: 1.125rem;
  }
}
@media (max-width: 639px) {
  .BenefitsList__itemHeading {
    margin-bottom: 8px;
    font-size: 1rem;
  }
}
.BenefitsList__itemText {
  font-size: 1rem;
  color: #72757b;
}
@media (max-width: 999px) {
  .BenefitsList__itemText {
    font-size: 0.9375rem;
  }
}
@media (max-width: 639px) {
  .BenefitsList__itemText {
    font-size: 0.875rem;
  }
}

.CardImage__picture {
  display: block;
  position: relative;
  padding-bottom: 68.1818181818%;
  background-color: #dbdee2;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 24px;
}
@media (max-width: 999px) {
  .CardImage__picture {
    margin-bottom: 16px;
  }
}
@media (max-width: 899px) {
  .CardImage__picture {
    margin-bottom: 8px;
  }
}
.CardImage__image {
  transform: scale(1);
  transition: transform 0.4s ease-out;
}
.StructureYourLoanForm__footer {
  margin-top: 48px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 639px) {
  .StructureYourLoanForm__footer {
    flex-direction: column;
    margin-top: 32px;
  }
}
.StructureYourLoanForm__result {
  display: flex;
  align-items: center;
  margin-right: 38px;
}
@media (max-width: 639px) {
  .StructureYourLoanForm__result {
    margin-right: 0;
    margin-bottom: 24px;
  }
}
.StructureYourLoanForm__resultLabel {
  color: #929bad;
  font-size: 1.125rem;
  margin-right: 12px;
}
.StructureYourLoanForm__resultValue {
  color: #f35730;
  font-size: 1.5rem;
  min-width: 153px;
}

.LandingBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.LandingBlock__textWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 96px;
  margin-bottom: 32px;
  padding: 0 48px;
}
@media (max-width: 1439px) {
  .LandingBlock__textWrapper {
    grid-column-gap: 80px;
  }
}
@media (max-width: 999px) {
  .LandingBlock__textWrapper {
    padding: 0;
  }
}
@media (max-width: 899px) {
  .LandingBlock__textWrapper {
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
  }
}
@media (max-width: 639px) {
  .LandingBlock__textWrapper {
    grid-row-gap: 24px;
  }
}
.LandingBlock__text {
  color: #2b2f34;
}
.LandingBlock__video {
  width: 100%;
  max-width: 1000px;
}

@media (hover: hover) {
  .NewsCard__link:hover .CardImage__image, .NewsCard__link:active .CardImage__image, .NewsCard__link:focus .CardImage__image {
    transform: scale(1.1);
  }
}
.NewsCard__date {
  font-size: 0.875rem;
  color: #f35730;
  letter-spacing: 0.1px;
  font-weight: 500;
}
@media (max-width: 899px) {
  .NewsCard__date {
    font-size: 0.75rem;
  }
}
.NewsCard__title {
  font-size: 1rem;
  letter-spacing: 0.15px;
  font-weight: 500;
  margin-top: 8px;
}
@media (max-width: 999px) {
  .NewsCard__title {
    margin-top: 4px;
  }
}
@media (max-width: 899px) {
  .NewsCard__title {
    font-size: 0.875rem;
  }
}

.ProjectCard {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ProjectCard__location {
  line-height: 1.6;
}
@media (max-width: 639px) {
  .ProjectCard__location {
    font-weight: 500;
  }
}
.ProjectCard__details {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
  margin-top: 16px;
}
@media (max-width: 1199px) {
  .ProjectCard__details {
    grid-row-gap: 12px;
  }
}
@media (max-width: 424px) {
  .ProjectCard__details {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 12px;
  }
}
.ProjectCard__detail {
  display: grid;
  grid-template-columns: 115px auto;
  grid-gap: 24px;
}
@media (max-width: 1199px) {
  .ProjectCard__detail {
    grid-template-columns: auto;
    grid-gap: 4px;
  }
}
@media (max-width: 899px) {
  .ProjectCard__detail {
    font-size: 0.875rem;
  }
}
.ProjectCard__detailName {
  color: #72757b;
}
.ProjectCard__detailValue {
  color: #2b2f34;
}
.ProjectCard__loanAmount {
  display: block;
  color: #f35730;
  font-size: 1.5rem;
  margin-top: auto;
  padding-top: 18px;
}
@media (max-width: 899px) {
  .ProjectCard__loanAmount {
    padding-top: 12px;
    font-size: 1.25rem;
  }
}

.StepsList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 48px;
}
@media (max-width: 1199px) {
  .StepsList {
    grid-gap: 40px;
  }
}
@media (max-width: 999px) {
  .StepsList {
    grid-gap: 32px;
  }
}
@media (max-width: 899px) {
  .StepsList {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 639px) {
  .StepsList {
    grid-gap: 16px;
  }
}
@media (max-width: 424px) {
  .StepsList {
    grid-template-columns: 1fr;
  }
}
.StepsList__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px 32px;
  text-align: center;
  line-height: 1.8;
  background: linear-gradient(180deg, #dadfeb 0%, white 100%);
  border-radius: 20px;
}
@media (max-width: 1199px) {
  .StepsList__item {
    padding: 20px 24px 24px;
  }
}
@media (max-width: 999px) {
  .StepsList__item {
    padding: 16px 20px 20px;
  }
}
.StepsList__pictureWrapper {
  width: 100%;
  margin-bottom: 12px;
  padding: 0 24px;
}
@media (max-width: 1199px) {
  .StepsList__pictureWrapper {
    margin-bottom: 10px;
    padding: 0 20px;
  }
}
@media (max-width: 999px) {
  .StepsList__pictureWrapper {
    padding: 0 16px;
  }
}
@media (max-width: 639px) {
  .StepsList__pictureWrapper {
    margin-bottom: 8px;
  }
}
.StepsList__picture {
  display: block;
  position: relative;
  padding-bottom: 70.2479338843%;
  background-color: transparent;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.StepsList__itemHeading {
  margin-bottom: 4px;
  font-size: 1.25rem;
  color: #2b2f34;
}
@media (max-width: 1199px) {
  .StepsList__itemHeading {
    font-size: 1.125rem;
  }
}
@media (max-width: 639px) {
  .StepsList__itemHeading {
    font-size: 1rem;
  }
}
.StepsList__itemText {
  font-size: 1rem;
  color: #72757b;
}
@media (max-width: 1199px) {
  .StepsList__itemText {
    font-size: 0.9375rem;
  }
}
@media (max-width: 639px) {
  .StepsList__itemText {
    font-size: 0.875rem;
  }
}

.ValueSlider__fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  margin-bottom: 24px;
}
.ValueSlider__control {
  padding-x: 8px;
  margin-y: 0;
}

.YoutubeVideo {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #edeff2;
  cursor: pointer;
}
.YoutubeVideo--landing {
  padding-bottom: 56.25%;
  border-radius: 20px;
}
.YoutubeVideo--landing:hover .YoutubeVideo__playButton {
  box-shadow: 0 0 0 8px rgba(243, 87, 48, 0.4);
}
.YoutubeVideo--landing:active .YoutubeVideo__playButton {
  box-shadow: 0 0 0 6px rgba(243, 87, 48, 0.4);
}
.YoutubeVideo--landing .YoutubeVideo__playButton {
  size: 84px;
  box-shadow: 0 0 0 12px rgba(243, 87, 48, 0.4);
}
@media (max-width: 424px) {
  .YoutubeVideo--landing .YoutubeVideo__playButton {
    size: 64px;
    box-shadow: 0 0 0 10px rgba(243, 87, 48, 0.4);
  }
}
.YoutubeVideo--landing .YoutubeVideo__playButtonIconWrapper {
  left: 4px;
  width: 28px;
}
@media (max-width: 424px) {
  .YoutubeVideo--landing .YoutubeVideo__playButtonIconWrapper {
    left: 2px;
    width: 24px;
  }
}
.YoutubeVideo--card {
  padding-bottom: 68.1818181818%;
  border-radius: 20px 20px 0 0;
}
.YoutubeVideo--card .YoutubeVideo__playButton {
  size: 54px;
}
.YoutubeVideo--card .YoutubeVideo__playButtonIconWrapper {
  left: 2px;
  width: 20px;
}
.YoutubeVideo--loading .YoutubeVideo__playButtonIconWrapper {
  display: none;
}
.YoutubeVideo--loading .YoutubeVideo__spinner {
  display: block;
}
.YoutubeVideo--loaded .YoutubeVideo__picture,
.YoutubeVideo--loaded .YoutubeVideo__playButton {
  opacity: 0;
  pointer-events: none;
}
.YoutubeVideo:hover .YoutubeVideo__picture {
  transform: scale3d(1.1, 1.1, 1.1);
}
.YoutubeVideo:hover .YoutubeVideo__playButton {
  background-color: #f24318;
}
.YoutubeVideo__picture {
  z-index: 2;
  transition: transform 0.35s ease-out, opacity 0.25s;
}
.YoutubeVideo__picture, .YoutubeVideo__player {
  position: absolute;
  top: 0;
  left: 0;
  size: 100%;
}
.YoutubeVideo__image {
  object-fit: cover;
  size: 100%;
}
.YoutubeVideo__player {
  z-index: 1;
}
.YoutubeVideo__playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  border: none;
  border-radius: 50%;
  background-color: #f35730;
  color: white;
  cursor: pointer;
  transform: translate(-50%, -50%);
  z-index: 3;
  pointer-events: none;
  transition-property: background, box-shadow;
  transition: 0.25s ease-in-out;
}
.YoutubeVideo__playButtonIconWrapper {
  position: relative;
}
.YoutubeVideo__playButtoIcon {
  width: 100%;
}
.YoutubeVideo__spinner {
  display: none;
}

.HomePage__allNewsButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
@media (max-width: 999px) {
  .HomePage__allNewsButtonWrapper {
    margin-top: 32px;
  }
}
@media (max-width: 639px) {
  .HomePage__allNewsButtonWrapper {
    margin-top: 24px;
  }
}