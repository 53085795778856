.OffCanvas {
    display: flex;
    flex-direction: column;
    max-width: 100vw;

    @include vw-xsmall-down {
        width: 100vw;
    }

    &__header {
        display: flex;
        flex-shrink: 0;
        height: var(--header-border-box-height);
    }

    &__footer {
        margin-top: auto;
        padding-y: 32px;
    }

    &__button {
        width: 100%;
    }

    &__container {
        width: 100%;
        padding-x: $container-padding-horizontal-m;

        @include vw-medium-down {
            padding-x: $container-padding-horizontal-s;
        }

        @include vw-small-down {
            padding-x: $container-padding-horizontal;
        }
    }

    &__icons {
        display: flex;
        align-items: center;
        height: 100%;
    }
}
