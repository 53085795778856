.CardImage {
    &__picture {
        @include aspect-ratio-picture(352, 240);

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin-bottom: 24px;

        @include vw-large-down {
            margin-bottom: 16px;
        }

        @include vw-medium-down {
            margin-bottom: 8px;
        }
    }

    &__image {
        @include image-with-scale;
    }

    &__placeholder {
        @extend %image-placeholder;
    }
}