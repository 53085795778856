// misc

@keyframes shine-loader {
    100% {
        background-position: -100%;
    }
}

// remove loading 'alt' text for firefox images
img:-moz-loading {
    color: transparent;
}

// remove skeleton loader after image load
// if you find bugs related to this solution
// ask @lnd_feip
:root .yall-loaded {
    background: transparent none;
    animation: none;
}

// hack to change auto-fill-colors
input:-webkit-autofill {
    &,
    &:hover,
    &:focus,
    &:active {
        -webkit-box-shadow: 0 0 0 1000px $form-focus-background inset;
        -webkit-text-fill-color: $form-focus-color;
    }
}

.tableWrapper {
    overflow: auto;
}
