.SiteFooter {
    background-color: $color-black;
    padding-top: 48px;

    @include vw-medium-down {
        padding-top: 32px;
    }

    &__container {
        @extend .uk-container, .uk-container-large;
    }

    &__grid {
        $columns-template: minmax(186px, max-content) minmax(min-content, 186px);

        display: grid;
        grid-template: auto / #{$columns-template};
        grid-gap: 54px;

        @include vw-medium-down {
            margin-bottom: 24px;
            grid-template-columns: 1fr 1fr;
            max-width: 426px;
            grid-gap: 24px;
        }

        @include vw-xsmall-down {
            grid-gap: 16px;
        }

        @include vw-xxsmall-down {
            grid-template: auto auto / 1fr;
        }
    }

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 24px;

        &--top {
            @include vw-medium-down {
                flex-direction: column;
            }
        }

        &--bottom {
            align-items: center;
            padding-top: 24px;
            border-top: 1px solid transparentize($color-gray--light, 0.75);

            @include vw-small-down {
                flex-direction: column;
                align-items: stretch;
            }
        }
    }

    &__logo {
        @extend %transparentize-states;

        display: flex;
        align-items: center;
        min-height: $button-height;
        margin-bottom: 12px;

        @include vw-xlarge-down {
            min-height: $button-height-l;
        }

        @include vw-small-down {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__logoImage {
        flex-shrink: 0;
        width: 244px;
    }

    &__logoText {
        margin-left: 24px;
        font-size: toRem(18px);
        line-height: 1.8;
        color: $color-gray--light;

        @include vw-large-down {
            margin-left: 20px;
            font-size: toRem(16px);
        }

        @include vw-small-down {
            margin: 4px 0 0;
            font-size: toRem(14px);
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;

        @include vw-medium-down {
            flex-direction: row;
            max-width: 426px;
            width: 100%;
        }

        @include vw-xsmall-down {
            flex-direction: column;
        }
    }

    &__getQuote,
    &__sendMessage {
        @include vw-medium-down {
            width: 100%;
        }
    }

    &__getQuote {
        &:not(:last-child) {
            margin-bottom: 24px;

            @include vw-medium-down {
                margin-bottom: 0;
                margin-right: 24px;
            }

            @include vw-xsmall-down {
                margin-bottom: 24px;
                margin-right: 0;
            }
        }
    }

    &__contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include vw-xsmall-down {
            max-width: 186px;
        }
    }

    &__contactsLink {
        @extend %nav-item;

        color: white;
        margin-bottom: 24px;

        &:hover,
        &:focus,
        &:active {
            color: $color-orange;
        }
    }

    &__contactsElement {
        color: $color-gray--light;
        font-size: toRem(14px);
        line-height: 1.8;

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &--link {
            outline: none;

            &:hover,
            &:focus,
            &:active {
                color: $color-orange;
            }
        }
    }

    &__phone:not(:last-child) {
        margin-bottom: 8px;
    }

    &__copyright {
        color: $color-gray--light;

        @include vw-small-down {
            margin-bottom: 16px;
        }
    }

    &__feipLogo {
        color: $color-gray--light;
        transition: color 0.25s ease-in-out;

        @include vw-small-down {
            margin-left: auto;
        }

        // feip logo
        &:hover,
        &:focus,
        &:active {
            color: $color-orange;
        }

        svg {
            transition: fill 0.25s ease-in-out;
            fill: $color-gray--light;

            // developed by
            &:hover,
            &:focus,
            &:active {
                fill: $color-orange;
            }
        }
    }
}
