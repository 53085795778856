.ProjectCard {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__location {
        @extend %heading-small;

        line-height: 1.6;

        @include vw-small-down {
            font-weight: $font-weight-medium;
        }
    }

    &__details {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 8px;
        margin-top: 16px;

        @include vw-xlarge-down {
            grid-row-gap: 12px;
        }

        @include vw-xsmall-down {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 12px;
        }
    }

    &__detail {
        display: grid;
        grid-template-columns: 115px auto;
        grid-gap: 24px;

        @include vw-xlarge-down {
            grid-template-columns: auto;
            grid-gap: 4px;
        }

        @include vw-medium-down {
            font-size: toRem(14px);
        }
    }

    &__detailName {
        color: $color-gray;
    }

    &__detailValue {
        color: $color-black;
    }

    &__loanAmount {
        display: block;
        color: $color-orange;
        font-size: toRem(24px);
        margin-top: auto;
        padding-top: 18px;

        @include vw-medium-down {
            padding-top: 12px;
            font-size: toRem(20px);
        }
    }
}
