// This file contains placeholders and mixins

@mixin skeleton($color1: $color-gray--lighter, $color2: $color-gray--light) {
    background-image: linear-gradient(
        to right,
        $color1 25%,
        $color2 50%,
        $color1 85%
    );
    background-size: 600%;
    background-position: 0;
    will-change: background-position;
    animation: shine-loader 3s infinite ease-out;
}

@mixin custom-schema($prefix, $map) {
    @each $state, $scheme in $map {
        @each $prop, $color in $scheme {
            #{$prefix}-#{$state}-#{$prop}: #{$color};
        }
    }
}

%display-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

%full-size {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%full-size-image {
    @extend %full-size;

    object-fit: cover;
}

@mixin aspect-ratio-picture(
    $width: 1,
    $height: $width,
    $color-placeholder: $color-placeholder
) {
    display: block;
    position: relative;
    padding-bottom: aspect-ratio($width, $height);
    background-color: $color-placeholder;
    overflow: hidden;
    // Hack for safari
    -webkit-mask-image: -webkit-radial-gradient(white, black);
}

@mixin image-with-scale {
    @extend %full-size-image;

    transform: scale(1);
    transition: transform 0.4s ease-out;
}

@mixin image-with-scale-active {
    transform: scale(1.1);
}

%image-placeholder {
    position: absolute;
    width: 62px;
    height: 62px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin full-width-block {
    width: calc(100% + #{$container-padding-horizontal-m} * 2);
    margin-x: #{-$container-padding-horizontal-m};

    @include vw-medium-down {
        width: calc(100% + #{$container-padding-horizontal-s} * 2);
        margin-x: #{-$container-padding-horizontal-s};
    }

    @include vw-small-down {
        width: calc(100% + #{$container-padding-horizontal} * 2);
        margin-x: #{-$container-padding-horizontal};
    }
}

%transparentize-states {
    transition: opacity 0.2s ease-in-out;
    outline: none;

    &:hover,
    &:focus {
        opacity: 0.7;
    }

    &:active {
        opacity: 0.5;
    }
}

@mixin flex-grid($gutter, $gutterVertical: $gutter) {
    display: flex;
    margin: #{-$gutterVertical / 2} #{-$gutter / 2};
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
}

@mixin flex-column($columnsCount, $gutter, $gutterVertical: $gutter) {
    padding: $gutterVertical / 2 $gutter / 2;
    flex: 0 0 #{100% / $columnsCount};
    max-width: #{100% / $columnsCount};
}
