.ValueSlider {
    &__fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        margin-bottom: 24px;
    }

    &__field {
        @extend .uk-input;
    }

    &__control {
        padding-x: $slider-handle-size / 2;
        margin-y: 0;
    }
}
