// This file contains all custom variables of this project

$root-font-size: 16px;

// Colors
$color-orange: #f35730;
$color-orange--lighter: #eaac9c;
$color-blue: #297df8;
$color-blue--dark: #225493;
$color-red: #df1e1e;
$color-red--light: #f3d6d6;
$color-green: #009c5b;
$color-green--light: #00ac64;
$color-black: #2b2f34;
$color-gray: #72757b;
$color-gray--dark: #54585c;
$color-gray--light: #929bad;
$color-gray--lighter: #edeff2;

$color-placeholder: #dbdee2;

// Fonts
$font-family-base: Poppins, sans-serif;
$font-family-headings: $font-family-base;

// Font weights
$font-weight-regular: 400;
$font-weight-medium: 500;

// Local variables
$slider-handle-size: 16px;

// Layers
$z-index-offcanvas: 1001; // increased by 1 to fix conflicts with map
$z-index-header-burger: $z-index-offcanvas + 1;
