.LandingBlock {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__textWrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 96px;
        margin-bottom: 32px;
        padding: 0 48px;

        @include vw-xxlarge-down {
            grid-column-gap: 80px;
        }

        @include vw-large-down {
            padding: 0;
        }

        @include vw-medium-down {
            grid-template-columns: 1fr;
            grid-row-gap: 32px;
        }

        @include vw-small-down {
            grid-row-gap: 24px;
        }
    }

    &__text {
        color: $color-black;

        &--main {
            @extend %heading-xlarge;
        }

        &--additional {
            @extend %heading-xsmall;
        }
    }

    &__video {
        width: 100%;
        max-width: 1000px;
    }
}
