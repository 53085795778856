.Layout {
    display: flex;
    flex-flow: column;
    min-height: 100vh;

    &__header {
        flex-shrink: 0;
    }

    &__body {
        flex-grow: 1;

        &--centerVertically {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
        }
    }

    &__footer {
        flex-shrink: 0;
    }

    &__section {
        padding-y: 48px 80px;

        @include vw-xlarge-down {
            padding-y: 40px 72px;
        }

        @include vw-large-down {
            padding-y: 32px 64px;
        }

        @include vw-medium-down {
            padding-y: 24px 56px;
        }

        @include vw-small-down {
            padding-y: 24px 40px;
        }

        @include vw-xsmall-down {
            padding-y: 24px 32px;
        }

        &--largeTopGap {
            padding-top: 80px;

            @include vw-xlarge-down {
                padding-top: 72px;
            }

            @include vw-large-down {
                padding-top: 64px;
            }

            @include vw-medium-down {
                padding-top: 56px;
            }

            @include vw-small-down {
                padding-top: 40px;
            }

            @include vw-xsmall-down {
                padding-top: 32px;
            }
        }

        &--smallGap {
            padding-y: 44px;

            @include vw-xlarge-down {
                padding-y: 40px;
            }

            @include vw-large-down {
                padding-y: 36px;
            }

            @include vw-medium-down {
                padding-y: 32px;
            }

            @include vw-small-down {
                padding-y: 28px;
            }

            @include vw-xsmall-down {
                padding-y: 24px;
            }
        }

        &--smallTopGap {
            padding-top: 44px;

            @include vw-xlarge-down {
                padding-top: 40px;
            }

            @include vw-large-down {
                padding-top: 36px;
            }

            @include vw-medium-down {
                padding-top: 32px;
            }

            @include vw-small-down {
                padding-top: 28px;
            }

            @include vw-xsmall-down {
                padding-top: 24px;
            }
        }

        &--smallBottomGap {
            padding-bottom: 44px;

            @include vw-xlarge-down {
                padding-bottom: 40px;
            }

            @include vw-large-down {
                padding-bottom: 36px;
            }

            @include vw-medium-down {
                padding-bottom: 32px;
            }

            @include vw-small-down {
                padding-bottom: 28px;
            }

            @include vw-xsmall-down {
                padding-bottom: 24px;
            }
        }

        &--noBottomGap {
            padding-bottom: 0;
        }

        &--noTopGap {
            padding-top: 0;
        }

        &--gray {
            background-color: $color-gray--lighter;
        }

        &--halfGray {
            @include vw-medium-up {
                background: linear-gradient(
                    180deg,
                    white 60%,
                    $color-gray--lighter 60%
                );
            }
        }

        &--littleGrayMobile {
            @include vw-medium-down {
                background: linear-gradient(
                    180deg,
                    white 70%,
                    $color-gray--lighter 70%
                );
            }

            @include vw-xsmall-down {
                background: linear-gradient(
                    180deg,
                    white 80%,
                    $color-gray--lighter 80%
                );
            }
        }

        &--mostlyGrayMobile {
            @include vw-medium-down {
                background: linear-gradient(
                    180deg,
                    white 40%,
                    $color-gray--lighter 40%
                );
            }

            @include vw-xsmall-down {
                background: linear-gradient(
                    180deg,
                    white 25%,
                    $color-gray--lighter 25%
                );
            }
        }
    }

    &__title {
        @extend %heading-xxlarge;

        margin-bottom: 48px;
        color: $color-black;

        @include vw-large-down {
            margin-bottom: 40px;
        }

        @include vw-small-down {
            margin-bottom: 32px;
        }

        @include vw-xsmall-down {
            margin-bottom: 24px;
        }

        &--centered {
            text-align: center;
        }

        &--xlarge {
            @extend %heading-xlarge;

            margin-bottom: 40px;

            @include vw-large-down {
                margin-bottom: 32px;
            }

            @include vw-small-down {
                margin-bottom: 24px;
            }

            @include vw-xsmall-down {
                margin-bottom: 16px;
            }
        }
    }

    &__subtitle {
        font-size: toRem(18px);
        color: $color-gray;
        text-align: center;
        margin-top: -32px;
        margin-bottom: 48px;

        @include vw-large-down {
            margin-top: -24px;
            margin-bottom: 40px;
        }

        @include vw-small-down {
            margin-top: -24px;
            margin-bottom: 32px;
            font-size: toRem(16px);
        }

        @include vw-xsmall-down {
            margin-top: -16px;
        }
    }

    &__container {
        @extend .uk-container;

        &--large {
            @extend .uk-container-large;
        }

        &--small {
            @extend .uk-container-small;
        }

        &--smallest {
            max-width: $container-smallest-max-width;
        }
    }
}
