.BenefitsList {
    &__title {
        @extend %heading-xlarge;

        margin-bottom: 48px;
        text-align: center;
        color: $color-black;

        @include vw-large-down {
            margin-bottom: 40px;
        }

        @include vw-xsmall-down {
            margin-bottom: 32px;
        }
    }

    &__list {
        @include flex-grid(60px, 40px);

        @include vw-large-down {
            @include flex-grid(48px, 40px);
        }

        @include vw-xsmall-down {
            @include flex-grid(48px, 32px);
        }
    }

    &__item {
        @include flex-column(3, 60px, 40px);

        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        line-height: 1.8;

        @include vw-large-down {
            @include flex-column(3, 48px, 40px);
        }

        @include vw-medium-down {
            @include flex-column(2, 48px, 40px);
        }

        @include vw-xsmall-down {
            @include flex-column(1, 48px, 32px);

            flex-direction: row;
            text-align: left;
        }
    }

    &__itemContent {
        @include vw-xsmall-down {
            margin-left: 16px;
        }
    }

    &__image {
        margin-bottom: 16px;
        size: 80px;
        flex-shrink: 0;

        @include vw-large-down {
            margin-bottom: 14px;
        }

        @include vw-small-down {
            margin-bottom: 12px;
        }

        @include vw-xsmall-down {
            margin-bottom: 0;
            align-self: flex-start;
            margin-top: 4px;
        }
    }

    &__itemHeading {
        margin-bottom: 12px;
        font-size: toRem(20px);
        color: $color-black;

        @include vw-large-down {
            margin-bottom: 10px;
            font-size: toRem(18px);
        }

        @include vw-small-down {
            margin-bottom: 8px;
            font-size: toRem(16px);
        }
    }

    &__itemText {
        font-size: toRem(16px);
        color: $color-gray;

        @include vw-large-down {
            font-size: toRem(15px);
        }

        @include vw-small-down {
            font-size: toRem(14px);
        }
    }
}
