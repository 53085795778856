$fields-gap-vertical: 32px;
$fields-gap-horizontal: 32px;
$fields-gap-vertical-xs: 24px;
$fields-gap-horizontal-xs: 24px;
$fields-gap-vertical-small: 24px;
$fields-gap-horizontal-small: 24px;
$fields-gap-vertical-small-xs: 24px;
$fields-gap-horizontal-small-xs: 24px;

$form-label-color: rgba($color-black, 0.5);
$form-error-color: $color-red;
$form-error-background-color: $color-red--light;
$form-required-star-color: $color-red;
$form-agreement-color: $color-gray;

$form-label-font-size: toRem(14px);

$form-default-schema: (
    default: (
        color: $color-black,
        background: white,
        border: transparent,
        option-color: $color-black,
        option-background: white,
    ),
    hover: (
        color: $color-black,
        background: white,
        border: transparent,
        option-color: $color-black,
        option-background: darken(white, 5%),
    ),
    active: (
        color: $color-black,
        background: white,
        border: $color-blue,
        option-color: $color-gray--light,
        option-background: white,
    ),
    disabled: (
        color: rgba($color-gray--light, 0.5),
        background: rgba(white, 0.5),
        border: transparent,
    ),
);

.SiteForm {
    $block: &;

    --fields-gap-vertical: #{$fields-gap-vertical};
    --fields-gap-horizontal: #{$fields-gap-horizontal};
    --cell-margin-vertical: calc(var(--fields-gap-vertical) / 2);
    --cell-margin-horizontal: calc(var(--fields-gap-horizontal) / 2);

    @include custom-schema("--form", $form-default-schema);

    @include vw-xsmall-down {
        --fields-gap-vertical: #{$fields-gap-vertical-xs};
        --fields-gap-horizontal: #{$fields-gap-horizontal-xs};
    }

    &--small {
        --fields-gap-vertical: #{$fields-gap-vertical-small};
        --fields-gap-horizontal: #{$fields-gap-horizontal-small};

        @include vw-xsmall-down {
            --fields-gap-vertical: #{$fields-gap-vertical-small-xs};
            --fields-gap-horizontal: #{$fields-gap-horizontal-small-xs};
        }
    }

    &__title {
        @extend %heading-xlarge;

        margin-bottom: 32px;
        color: $color-black;

        @include vw-small-down {
            margin-bottom: 24px;
        }
    }

    &__subtitle {
        @extend %heading-medium;

        margin-bottom: 24px;
        color: $color-black;

        @include vw-small-down {
            margin-bottom: 16px;
        }

        #{$block}__grid + & {
            margin-top: 40px;

            @include vw-small-down {
                margin-top: 32px;
            }
        }
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        margin: calc(-1 * var(--cell-margin-vertical))
            calc(-1 * var(--cell-margin-horizontal));

        & + #{$block}__grid {
            padding-top: 32px;

            @include vw-large-down {
                padding-top: 24px;
            }

            @include vw-xsmall-down {
                padding-top: 16px;
            }
        }
    }

    &__cell {
        $el: &;

        position: relative;
        width: calc(100% - var(--fields-gap-horizontal));
        margin: var(--cell-margin-vertical) var(--cell-margin-horizontal);

        &--col-1-2 {
            @include vw-small-up {
                width: calc(50% - var(--fields-gap-horizontal));
            }
        }

        &--col-1-3 {
            @include vw-small-up {
                width: calc(50% - var(--fields-gap-horizontal));
            }

            @include vw-medium-up {
                width: calc(33.3333% - var(--fields-gap-horizontal));
            }
        }
    }

    &__input {
        @extend .uk-input;
    }

    &__textarea {
        @extend .uk-textarea;
    }

    &__select {
        @extend .uk-select;

        html:root:root & {
            background-position-x: calc(100% - 12px);
        }
    }

    %active-label {
        transform: scale(0.7)
            translateY(
                calc(#{$form-height} / -2 + #{$form-label-font-size} / 1.6)
            );
        pointer-events: none;
    }

    &__input,
    &__textarea {
        &:not(&--showPlaceholder) {
            &::placeholder {
                opacity: 0;
                user-select: none;
            }

            // Edge and Internet Explorer
            &:-ms-input-placeholder,
            &::-ms-input-placeholder {
                opacity: 0; // Works in all browsers!
                user-select: none;
            }
        }

        &:not(:placeholder-shown),
        &:focus {
            & + #{$block}__label {
                @extend %active-label;
            }
        }

        :root &--invalid {
            color: $form-error-color;
            background-color: $form-error-background-color;
            border-color: $form-error-background-color;

            &:focus {
                color: var(--form-active-color);
                background-color: var(--form-active-background);
                border-color: var(--form-active-border);
            }

            + #{$block}__label {
                color: $form-error-color;
            }
        }
    }

    &__label {
        position: absolute;
        top: calc((#{$form-height} - #{$form-label-font-size}) / 2);
        left: $form-padding-horizontal - 1px;
        border-radius: 3px;
        padding: 0 4px;
        color: $form-label-color;
        font-size: $form-label-font-size;
        line-height: 1;
        cursor: text;
        white-space: nowrap;
        user-select: none;
        transform-origin: top left;
        transition: 0.25s ease-in-out;
        transition-property: transform, color;
        pointer-events: none;

        &--active {
            @extend %active-label;
        }

        // Dropdown
        .vs--open + & {
            @extend %active-label;
        }

        .vs--invalid + & {
            color: $form-error-color;
        }

        &--required {
            &::after {
                content: "*";
                color: $form-required-star-color;
            }
        }
    }

    &__error {
        display: block;
        position: relative;
        margin-top: 4px;
        font-size: toRem(14px);
        color: $form-error-color;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        margin-top: 32px;

        @include vw-xsmall-down {
            margin-top: 24px;
        }

        &--left {
            align-items: start;
            text-align: left;
        }

        &--withAgreement {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 48px;

            @include vw-large-down {
                margin-top: 40px;
            }

            @include vw-xsmall-down {
                flex-direction: column;
                align-items: flex-start;
                margin-top: 32px;
            }

            #{$block}__agreement {
                margin-left: 40px;
                font-size: toRem(16px);

                @include vw-small-down {
                    margin-left: 32px;
                    font-size: toRem(14px);
                }

                @include vw-xsmall-down {
                    margin-left: 0;
                    margin-top: 16px;
                }
            }
        }
    }

    &__agreement {
        font-size: toRem(12px);
        color: $form-agreement-color;

        &--bottomGap {
            margin-bottom: 32px;

            @include vw-xsmall-down {
                margin-bottom: 24px;
            }
        }

        a {
            color: inherit;
            text-decoration: underline;

            &:hover,
            &:focus,
            &:active {
                color: $color-red;
                text-decoration: underline;
            }
        }
    }
}

.Checkbox,
.Radio {
    $block: &;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;

    &__control {
        flex-shrink: 0;
    }

    &__label {
        margin-left: 12px;
        font-size: toRem(14px);
        line-height: 1.8;
        color: black;
        transition: color 0.2s ease-in-out;
    }
}

.Checkbox {
    $block: &;

    &--invalid {
        #{$block}__control {
            // override default hover
            &,
            &:hover:not(:checked):not(:focus) {
                background-color: transparentize($form-error-color, 0.5);
            }
        }
    }

    &__control {
        @extend .uk-checkbox;

        :root & {
            size: $form-checkbox-size;
            border-radius: $form-border-radius;

            &:hover,
            &:focus {
                background-color: rgba($color-green, 0.5);
            }
        }
    }

    &__label {
        line-height: $form-checkbox-size;
    }
}
