.StepsList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 48px;

    @include vw-xlarge-down {
        grid-gap: 40px;
    }

    @include vw-large-down {
        grid-gap: 32px;
    }

    @include vw-medium-down {
        grid-template-columns: repeat(2, 1fr);
    }

    @include vw-small-down {
        grid-gap: 16px;
    }

    @include vw-xsmall-down {
        grid-template-columns: 1fr;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 32px 32px;
        text-align: center;
        line-height: 1.8;
        background: linear-gradient(180deg, #dadfeb 0%, white 100%);
        border-radius: 20px;

        @include vw-xlarge-down {
            padding: 20px 24px 24px;
        }

        @include vw-large-down {
            padding: 16px 20px 20px;
        }
    }

    &__pictureWrapper {
        width: 100%;
        margin-bottom: 12px;
        padding: 0 24px;

        @include vw-xlarge-down {
            margin-bottom: 10px;
            padding: 0 20px;
        }

        @include vw-large-down {
            padding: 0 16px;
        }

        @include vw-small-down {
            margin-bottom: 8px;
        }
    }

    &__picture {
        @include aspect-ratio-picture(242, 170, transparent);
    }

    &__image {
        @extend %full-size-image;
    }

    &__itemHeading {
        margin-bottom: 4px;
        font-size: toRem(20px);
        color: $color-black;

        @include vw-xlarge-down {
            font-size: toRem(18px);
        }

        @include vw-small-down {
            font-size: toRem(16px);
        }
    }

    &__itemText {
        font-size: toRem(16px);
        color: $color-gray;

        @include vw-xlarge-down {
            font-size: toRem(15px);
        }

        @include vw-small-down {
            font-size: toRem(14px);
        }
    }
}
