.HomePage {
    &__allNewsButtonWrapper {
        width: 100%;
        display: flex;
        justify-content: center;

        margin-top: 48px;

        @include vw-large-down {
            margin-top: 32px;
        }

        @include vw-small-down {
            margin-top: 24px;
        }
    }
}
