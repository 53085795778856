.YoutubeVideo {
    $block: &;

    $scale: 1.1;

    $player-z-index: 1;
    $picture-z-index: $player-z-index + 1;
    $play-button-z-index: $picture-z-index + 1;

    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: $color-gray--lighter;
    cursor: pointer;

    &--landing {
        padding-bottom: aspect-ratio(16, 9);
        border-radius: 20px;

        &:hover {
            #{$block}__playButton {
                box-shadow: 0 0 0 8px rgba($color-orange, 0.4);
            }
        }

        &:active {
            #{$block}__playButton {
                box-shadow: 0 0 0 6px rgba($color-orange, 0.4);
            }
        }

        #{$block}__playButton {
            size: 84px;
            box-shadow: 0 0 0 12px rgba($color-orange, 0.4);

            @include vw-xsmall-down {
                size: 64px;
                box-shadow: 0 0 0 10px rgba($color-orange, 0.4);
            }
        }

        #{$block}__playButtonIconWrapper {
            left: 4px;
            width: 28px;

            @include vw-xsmall-down {
                left: 2px;
                width: 24px;
            }
        }
    }

    &--card {
        padding-bottom: aspect-ratio(352, 240);
        border-radius: 20px 20px 0 0;

        #{$block}__playButton {
            size: 54px;
        }

        #{$block}__playButtonIconWrapper {
            left: 2px;
            width: 20px;
        }
    }

    &--loading {
        #{$block}__playButtonIconWrapper {
            display: none;
        }

        #{$block}__spinner {
            display: block;
        }
    }

    &--loaded {
        #{$block}__picture,
        #{$block}__playButton {
            opacity: 0;
            pointer-events: none;
        }
    }

    &:hover {
        #{$block}__picture {
            transform: scale3d($scale, $scale, $scale);
        }

        #{$block}__playButton {
            background-color: darken($color-orange, 5%);
        }
    }

    &__picture {
        z-index: $picture-z-index;
        transition: transform 0.35s ease-out, opacity 0.25s;
    }

    &__picture,
    &__player {
        position: absolute;
        top: 0;
        left: 0;
        size: 100%;
    }

    &__image {
        object-fit: cover;
        size: 100%;
    }

    &__player {
        z-index: $player-z-index;
    }

    &__playButton {
        @extend %display-flex-center;

        position: absolute;
        top: 50%;
        left: 50%;
        border: none;
        border-radius: 50%;
        background-color: $color-orange;
        color: white;
        cursor: pointer;
        transform: translate(-50%, -50%);
        z-index: $play-button-z-index;
        pointer-events: none;

        transition-property: background, box-shadow;
        transition: 0.25s ease-in-out;
    }

    &__playButtonIconWrapper {
        @extend %display-flex-center;

        position: relative;
    }

    &__playButtoIcon {
        width: 100%;
    }

    &__spinner {
        display: none;
    }
}
