.SiteModal {
    &__title {
        @extend %heading-xlarge;

        margin-bottom: 24px;

        @include vw-xsmall-down {
            margin-bottom: 16px;
        }
    }
}
