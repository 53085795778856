.NewsCard {
    &__link {
        @media (hover: hover) {
            &:hover,
            &:active,
            &:focus {
                & .CardImage__image {
                    @include image-with-scale-active;
                }
            }
        }
    }

    &__date {
        font-size: toRem(14px);
        color: $color-orange;
        letter-spacing: 0.1px;
        font-weight: $font-weight-medium;

        @include vw-medium-down {
            font-size: toRem(12px);
        }
    }

    &__title {
        font-size: toRem(16px);
        letter-spacing: 0.15px;
        font-weight: $font-weight-medium;
        margin-top: 8px;

        @include vw-large-down {
            margin-top: 4px;
        }

        @include vw-medium-down {
            font-size: toRem(14px);
        }
    }
}
