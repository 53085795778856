.CookiesMessage {
    @extend %display-flex-center;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 164px;
    padding: 32px $container-padding-horizontal-m;
    background-color: white;
    box-shadow: none;
    transform: translateY(100%);
    z-index: 10000;

    transition-property: transform, box-shadow;
    transition: 0.25s ease-in-out;

    @include vw-medium-down {
        padding: 32px $container-padding-horizontal-s;
    }

    @include vw-small-down {
        height: fit-content;
        padding: 32px $container-padding-horizontal;
    }

    &--shown {
        box-shadow: 10px 0 10px 4px rgba(black, 0.15);
        transform: translateY(0);
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 992px;

        @include vw-small-down {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__contentBlock {
        display: flex;
        align-items: flex-start;
        margin-right: 80px;

        @include vw-large-down {
            margin-right: 64px;
        }

        @include vw-small-down {
            margin-right: 0;
            margin-bottom: 24px;
        }
    }

    &__icon {
        flex-shrink: 0;
        size: 80px;

        @include vw-large-down {
            size: 64px;
        }
    }

    &__textBlock {
        display: flex;
        flex-direction: column;
        margin-left: 24px;
    }

    &__heading {
        font-size: toRem(28px);
        font-weight: $font-weight-medium;
        line-height: 1.3;
        margin-bottom: 8px;
        color: #323540;

        @include vw-xlarge-down {
            font-size: toRem(24px);
        }

        @include vw-large-down {
            font-size: toRem(20px);
        }
    }

    &__description {
        font-size: toRem(16px);
        line-height: 1.5;
        color: #999a9f;

        @include vw-large-down {
            font-size: toRem(14px);
        }

        a {
            text-decoration: underline;
            color: $color-blue;

            &:hover,
            &:focus,
            &:active {
                text-decoration: underline;
                color: $color-orange;
            }
        }
    }

    &__acceptButton {
        min-width: 160px;

        @include vw-small-down {
            min-width: 128px;
            height: 48px;
            margin-left: 88px;
            padding: 16px;
        }

        @include vw-xxsmall-down {
            width: fit-content;
        }
    }
}
