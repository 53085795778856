// Base
@mixin hook-base-link {
    transition: color 0.25s ease-in-out;
}

// Modal
@mixin hook-modal {
    backdrop-filter: blur(5px);
}

@mixin hook-modal-dialog {
    border-radius: 10px;
}

@mixin hook-modal-close {
    html:root & {
        position: absolute;
    }
}

@mixin hook-modal-body {
    @include vw-xsmall-down {
        padding: 40px 24px;
    }
}

// Form
@mixin hook-form() {
    border: 2px solid $form-border-color;
    padding-top: 14px;
    border-radius: $form-border-radius;
    font-size: $form-font-size;
    font-weight: $font-weight-medium;
    transition-property: border-color, background-color;
    transition: 0.25s ease-in-out;

    &:hover {
        color: $form-hover-color;
        background-color: $form-hover-background;
        border-color: $form-hover-border-color;
    }
}

@mixin hook-form-focus {
    // color & background set in uikit
    border-color: $form-focus-border-color;
}

@mixin hook-form-disabled {
    // color & background set in uikit
    border-color: $form-disabled-border-color;
}

@mixin hook-form-multi-line {
    padding-top: 25px;
    min-height: $form-height;
}
