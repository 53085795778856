%heading-xxlarge,
%heading-xlarge,
%heading-large {
    line-height: 1.6;
    font-weight: $font-weight-medium;
}

%heading-medium,
%heading-small,
%heading-xsmall {
    line-height: 1.8;
}

%heading-xxlarge {
    font-size: toRem(38px);

    @include vw-xlarge-down {
        font-size: toRem(36px);
    }

    @include vw-large-down {
        font-size: toRem(34px);
    }

    @include vw-medium-down {
        font-size: toRem(32px);
    }

    @include vw-small-down {
        font-size: toRem(28px);
    }
}

%heading-xlarge {
    font-size: toRem(32px);

    @include vw-xlarge-down {
        font-size: toRem(30px);
    }

    @include vw-large-down {
        font-size: toRem(28px);
    }

    @include vw-medium-down {
        font-size: toRem(24px);
    }

    @include vw-small-down {
        font-size: toRem(22px);
    }
}

%heading-large {
    font-size: toRem(28px);

    @include vw-large-down {
        font-size: toRem(26px);
    }

    @include vw-medium-down {
        font-size: toRem(22px);
    }

    @include vw-small-down {
        font-size: toRem(20px);
    }
}

%heading-medium {
    font-size: toRem(24px);

    @include vw-large-down {
        font-size: toRem(20px);
    }

    @include vw-small-down {
        font-size: toRem(18px);
    }
}

%heading-small {
    font-size: toRem(20px);

    @include vw-large-down {
        font-size: toRem(18px);
    }

    @include vw-small-down {
        font-size: toRem(16px);
    }
}

%heading-xsmall {
    font-size: toRem(18px);

    @include vw-large-down {
        font-size: toRem(16px);
    }
}

%heading-xxsmall {
    font-size: toRem(16px);
    font-weight: $font-weight-medium;
}
