@mixin button-base-state($state) {
    color: var(--button-#{$state}-color);
    border-color: var(--button-#{$state}-border);
    background-color: var(--button-#{$state}-background);

    .Button__icon {
        color: var(--button-#{$state}-icon-color, currentColor);
    }
}

@mixin button-base {
    @include button-base-state("default");

    &:hover {
        @include button-base-state("hover");
    }

    &:focus {
        @include button-base-state("focus");
    }

    &:active {
        @include button-base-state("active");
    }

    &:disabled {
        @include button-base-state("disabled");
    }
}

$button-black-schema: (
    default: (
        color: white,
        border: $color-black,
        background: $color-black,
    ),
    hover: (
        color: white,
        border: $color-blue--dark,
        background: $color-blue--dark,
    ),
    focus: (
        color: white,
        border: $color-gray--light,
        background: $color-black,
    ),
    active: (
        color: white,
        border: $color-blue,
        background: $color-blue,
    ),
    disabled: (
        color: $color-gray--light,
        border: $color-gray--light,
        background: transparent,
    ),
);

$button-outlined-blue-active-schema: (
    default: (
        color: #bdbdbd,
        border: $color-gray--light,
        background: white,
    ),
    hover: (
        color: white,
        border: $color-blue--dark,
        background: $color-blue--dark,
    ),
    focus: (
        color: #bdbdbd,
        border: $color-gray--light,
        background: #eeeeee,
    ),
    active: (
        color: white,
        border: $color-blue,
        background: $color-blue,
    ),
    disabled: (
        color: #d7d7d7,
        border: #d7d7d7,
        background: #fafafa,
    ),
);

$button-outlined-black-active-schema: (
    default: (
        color: $color-gray--light,
        border: $color-gray--light,
        background: white,
    ),
    hover: (
        color: $color-gray,
        border: $color-gray,
        background: $color-gray--lighter,
    ),
    focus: (
        color: $color-gray--light,
        border: $color-gray--light,
        background: #eeeeee,
    ),
    active: (
        color: white,
        border: $color-black,
        background: $color-black,
    ),
    disabled: (
        color: #d7d7d7,
        border: #d7d7d7,
        background: #fafafa,
    ),
);

$button-orange-schema: (
    default: (
        color: white,
        border: $color-orange,
        background: $color-orange,
    ),
    hover: (
        color: white,
        border: $color-gray--dark,
        background: $color-gray--dark,
    ),
    focus: (
        color: white,
        border: $color-black,
        background: $color-orange,
    ),
    active: (
        color: white,
        border: $color-blue,
        background: $color-blue,
    ),
    disabled: (
        color: $color-gray--light,
        border: $color-gray--light,
        background: transparent,
    ),
);

$button-white-schema: (
    default: (
        color: $color-black,
        border: white,
        background: white,
    ),
    hover: (
        color: $color-black,
        border: darken(white, 15%),
        background: darken(white, 15%),
    ),
    focus: (
        color: $color-black,
        border: darken(white, 15%),
        background: darken(white, 15%),
    ),
    active: (
        color: $color-black,
        border: darken(white, 20%),
        background: darken(white, 20%),
    ),
);

$button-white-transparent-schema: (
    default: (
        color: white,
        border: white,
        background: transparent,
    ),
    hover: (
        color: white,
        border: $color-blue--dark,
        background: $color-blue--dark,
    ),
    focus: (
        color: white,
        border: $color-blue--dark,
        background: $color-blue--dark,
    ),
    active: (
        color: white,
        border: $color-blue,
        background: $color-blue,
    ),
    disabled: (
        color: $color-gray--light,
        border: $color-gray--light,
        background: transparent,
    ),
);

$button-close-gray-schema: (
    default: (
        color: $color-black,
        background: transparentize($color-gray--light, 0.5),
    ),
    hover: (
        color: white,
        background: darken($color-orange--lighter, 10%),
    ),
    focus: (
        color: white,
        background: darken($color-orange--lighter, 10%),
    ),
    active: (
        color: white,
        background: darken($color-orange--lighter, 15%),
    ),
);

$button-orange-outlined-schema: (
    default: (
        color: $color-orange,
        border: $color-orange,
        background: transparent,
    ),
    hover: (
        color: $color-gray--dark,
        border: $color-gray--dark,
        background: transparent,
    ),
    focus: (
        color: $color-orange,
        border: $color-black,
        background: transparent,
    ),
    active: (
        color: $color-blue,
        border: $color-blue,
        background: transparent,
    ),
    disabled: (
        color: $color-gray--light,
        border: $color-gray--light,
        background: transparent,
    ),
);

@mixin button-black {
    @include custom-schema("--button", $button-black-schema);
}

@mixin button-orange {
    @include custom-schema("--button", $button-orange-schema);
}

@mixin button-white {
    @include custom-schema("--button", $button-white-schema);
}

@mixin button-outlined-blue-active {
    @include custom-schema("--button", $button-outlined-blue-active-schema);
}

@mixin button-outlined-black-active {
    @include custom-schema("--button", $button-outlined-black-active-schema);
}

@mixin button-white-transparent {
    @include custom-schema("--button", $button-white-transparent-schema);
}

@mixin button-close-gray-schema {
    @include custom-schema("--button", $button-close-gray-schema);
}

@mixin button-orange-outlined {
    @include custom-schema("--button", $button-orange-outlined-schema);
}

$button-height: 68px;
$button-height-l: 64px;
$button-height-xs: 56px;

@mixin button-empty {
    background: none;
    border: none;
    border-radius: 0;
    padding: 0;
    height: auto;
}

.Button {
    $block: &;

    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    overflow: visible;
    vertical-align: middle;
    appearance: none;

    min-width: 176px;
    height: $button-height;

    margin: 0;
    padding: 0 40px;

    font: inherit;
    font-weight: $font-weight-medium;
    font-size: toRem(16px);
    white-space: nowrap;
    text-decoration: none;

    border-style: solid;
    border-width: 2px;
    border-radius: 10px;

    transition: 0.25s ease-in-out;
    transition-property: color, border, background-color;

    // stylelint-disable-next-line order/order
    @include button-base;

    @include vw-xlarge-down {
        height: $button-height-l;
        padding-x: 32px;
    }

    @include vw-xsmall-down {
        height: $button-height-xs;
        font-size: toRem(14px);
    }

    @include vw-xxsmall-down {
        width: 100%;
    }

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }

    &:disabled {
        border-width: 1px;
    }

    &:not(:disabled) {
        cursor: pointer;
    }

    &--active {
        @include button-base-state("active");

        pointer-events: none;
    }

    &--noEvents {
        pointer-events: none;
    }

    :root &--loading {
        #{$block}__spinner {
            display: block;
        }

        #{$block}__text {
            visibility: hidden;
        }
    }

    &--black {
        @include button-black;
    }

    &--white {
        @include button-white;
    }

    &--outlinedBlueActive {
        @include button-outlined-blue-active;

        border-width: 1px;
    }

    &--outlinedBlackActive {
        @include button-outlined-black-active;

        border-width: 1px;
    }

    &--orange {
        @include button-orange;
    }

    &--whiteTransparent {
        @include button-white-transparent;

        border-width: 1px;
    }

    &--orangeOutlined {
        @include button-orange-outlined;

        border-width: 1px;
    }

    &--pagination {
        min-width: 48px;
        height: 48px;
        padding: 0;
        font-size: toRem(16px);
        line-height: 48px;
    }

    &--close {
        min-width: unset;
        width: 32px;
        height: 32px;
        padding: 0;
        border: none;
        border-radius: 6px;
    }

    &--closeGray {
        @include button-close-gray-schema;
    }

    &--empty {
        @include button-empty;
    }

    &__icon + &__text,
    &__text + &__icon {
        margin-left: 14px;
    }

    &__icon {
        @extend %display-flex-center;

        position: relative;
        flex-shrink: 0;
        width: 20px;

        svg {
            width: 100%;
            fill: currentColor;
        }
    }

    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        :root & {
            display: none;
        }
    }
}
