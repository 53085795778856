.CardsList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 48px;
    grid-row-gap: 40px;

    @include vw-xlarge-down {
        grid-column-gap: 40px;
        grid-row-gap: 36px;
    }

    @include vw-large-down {
        grid-column-gap: 36px;
        grid-row-gap: 24px;
    }

    @include vw-medium-down {
        grid-column-gap: 24px;
        grid-row-gap: 24px;
    }

    @include vw-small-down {
        grid-template-columns: repeat(2, 1fr);
    }

    @include vw-xsmall-down {
        grid-template-columns: 1fr;
    }

    &--withFilters {
        margin-top: 40px;

        @include vw-medium-down {
            margin-top: 32px;
        }
    }
}
