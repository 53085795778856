@import "elements/burger";
@import "elements/offcanvas";

:root {
    --header-vertical-padding: #{$container-padding-horizontal-s};
    --header-content-box-height: #{$button-height};
    --header-border-box-height: calc(
        var(--header-content-box-height) + var(--header-vertical-padding) * 2
    );

    @include vw-xlarge-down {
        --header-content-box-height: #{$button-height-l};
    }

    @include vw-small-down {
        --header-vertical-padding: #{$container-padding-horizontal};
    }
}

.SiteHeader {
    padding-y: var(--header-vertical-padding);

    &__container {
        @extend .uk-container, .uk-container-large;
    }

    &__flex {
        display: flex;
        align-items: center;
    }

    &__logo {
        @extend %transparentize-states;

        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-x: -35px 40px;
        min-height: $button-height;

        @include vw-xxlarge-down {
            margin-x: 0;
        }

        @include vw-xlarge-down {
            margin-x: 0 auto;
            min-height: $button-height-l;
        }

        @include vw-xxsmall-down {
            align-items: center;
        }
    }

    &__logoImage {
        width: 240px;

        @include vw-xxlarge-down {
            @include vw-xlarge-up {
                width: 200px;
            }
        }

        @include vw-xsmall-down {
            height: 7vw;
        }
    }

    &__logoText {
        margin-top: 4px;
        font-size: toRem(13px);
        line-height: 1.46;
        color: $color-gray;

        @include vw-xsmall-down {
            font-size: toRem(12px);
        }

        @include vw-xxsmall-down {
            font-size: toRem(11px);
        }
    }

    &__nav {
        margin-right: auto;

        @include vw-xxlarge-down {
            min-width: 500px;
            margin-x: auto;
        }

        @include vw-xlarge-down {
            display: none;
        }
    }

    &__icons {
        display: flex;
        margin-right: 40px;

        @include vw-xxlarge-down {
            @include vw-xlarge-up {
                margin-right: 32px;
            }
        }

        @include vw-xlarge-down {
            display: none;
        }
    }

    &__iconLink {
        @extend %transparentize-states;
    }

    &__phone:not(:last-child) {
        margin-right: 32px;

        @include vw-xxlarge-down {
            margin-right: 24px;
        }
    }

    &__button {
        margin-right: 0;

        @include vw-xxlarge-down {
            @include vw-xlarge-up {
                padding: 0 24px;
                height: 54px;
                min-width: unset;
            }
        }

        @include vw-xlarge-down {
            margin-right: 104px;
        }

        @include vw-medium-down {
            display: none;
        }
    }

    &__mobile {
        display: none;

        @include vw-xlarge-down {
            display: block;
        }
    }
}
