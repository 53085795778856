.MobileBurgerContainer {
    $z-index: $z-index-header-burger;

    @extend .uk-container;

    position: fixed;
    z-index: $z-index;
    left: 0;
    top: calc(var(--header-border-box-height) / 2);
    transform: translateY(-50%);
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;
    pointer-events: none;
}

.MobileBurger {
    $default-color: white;

    position: relative;
    cursor: pointer;
    display: inline-block;
    margin-right: 3px;
    padding: 16px;
    border: none;
    outline: none;
    transition: transform 0.2s ease-in-out;
    background: transparent;
    pointer-events: all;

    &:hover,
    &:focus,
    &:active,
    &[data-open="true"] {
        @include media-no-touch {
            &::before {
                opacity: 1;
                transform: scale(0.9);
            }

            &::after {
                opacity: 0.5;
                transform: scale(1.1);
            }
        }
    }

    &::before,
    &::after {
        @extend %full-size;

        content: "";
        display: block;
        background-color: $color-orange;
        border-radius: 50%;
        transition: 0.25s ease-in-out;
        transition-property: transform, opacity;
    }

    &::before {
        transform: scale(1.1);
        opacity: 0.5;
    }

    &::after {
        transform: scale(0.9);
    }

    &__outer {
        position: relative;
        z-index: 1;
        display: inline-block;
        width: 24px;
        height: 17px;
    }

    &__inner {
        top: 6px;
        display: block;

        &,
        &::after {
            position: absolute;
            width: 100%;
            height: 3px;
            border-radius: 2px;
            transition: background-color 0.25s ease-in-out, transform 0.15s ease,
                top 75ms ease 0.12s;
            background-color: white;
        }

        &::after {
            content: "";
            display: block;
        }

        &::after {
            top: auto;
            bottom: -9px;
            transition: background-color 0.25s ease-in-out,
                bottom 75ms ease 0.12s,
                transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }

    // open menu
    &[data-open="true"] &__inner {
        top: 11px;
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: rotate(45deg);

        &::after {
            bottom: 0;
            transition: background-color 0.25s ease-in-out, bottom 75ms ease,
                transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
            transform: rotate(-90deg);
        }
    }
}
