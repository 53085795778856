%nav-item {
    font-size: toRem(16px);
    font-weight: $font-weight-medium;
    letter-spacing: 0.15px;
    outline: none;
}

.SiteNav {
    $block: &;

    display: flex;

    &--modeHeader,
    &--modeOffcanvas {
        #{$block}__item {
            color: $color-gray--light;

            &--active {
                color: $color-orange;
            }
        }
    }

    &--modeFooter,
    &--modeOffcanvas {
        flex-direction: column;
    }

    &--modeFooter {
        #{$block}__listItem {
            margin: 0 0 24px; // m-bottom
        }

        #{$block}__item {
            color: white;

            &--active {
                color: $color-orange;
            }
        }
    }

    &--modeOffcanvas {
        #{$block}__listItem {
            margin: 0;
            border-bottom: 1px solid transparentize($color-black, 0.9);
        }

        #{$block}__item {
            display: block;
            padding: 16px $container-padding-horizontal-m;
            font-size: toRem(20px);
            -webkit-tap-highlight-color: transparentize(
                $color-gray--light,
                0.9
            );

            @include vw-medium-down {
                padding-x: $container-padding-horizontal-s;
            }

            @include vw-small-down {
                padding-x: $container-padding-horizontal;
            }
        }
    }

    &__listItem {
        margin: 0 24px 0 0; // m-right

        @include vw-xxlarge-down {
            margin-right: 16px;
        }

        &:last-child {
            margin: 0;
        }
    }

    &__item {
        @extend %nav-item;

        &--link {
            &:hover,
            &:focus,
            &:active {
                color: $color-orange;
            }
        }
    }
}
