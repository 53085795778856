.noUi-target {
    box-shadow: none;
    border: none;
    background-color: transparent;
}

.noUi-connect {
    background-color: $color-orange;
}

.noUi-handle {
    box-shadow: none;
    outline: none;
    width: $slider-handle-size;
    height: $slider-handle-size;
    top: -$slider-handle-size / 2;
    right: -$slider-handle-size / 2;
    border-radius: 50%;
    background-color: white;
    border: 2px $color-orange solid;
    transition: background-color 0.25s ease-in-out;
    cursor: pointer;

    &:hover,
    &:active {
        background-color: red;
    }

    &::after,
    &::before {
        content: unset;
    }
}

.noUi-horizontal {
    height: 2px;

    .noUi-handle {
        width: $slider-handle-size;
        height: $slider-handle-size;
        right: -$slider-handle-size / 2;
        top: -$slider-handle-size / 2;
    }
}
