.StructureYourLoanForm {
    &__footer {
        margin-top: 48px;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @include vw-small-down {
            flex-direction: column;
            margin-top: 32px;
        }
    }

    &__result {
        display: flex;
        align-items: center;
        margin-right: 38px;

        @include vw-small-down {
            margin-right: 0;
            margin-bottom: 24px;
        }
    }

    &__resultLabel {
        color: $color-gray--light;
        font-size: toRem(18px);
        margin-right: 12px;
    }

    &__resultValue {
        color: $color-orange;
        font-size: toRem(24px);
        min-width: 153px;
    }
}
