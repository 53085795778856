// This file is to tune UIKit variables

// Important reset styles
$base-margin-vertical: 0;
$base-heading-margin-top: 0;
$base-list-padding-left: 0;
$base-body-font-size: 100%;
$base-em-color: inherit;

$base-body-line-height: 1.5; // TODO: insert your value
$base-h1-line-height: $base-body-line-height;
$base-h2-line-height: $base-body-line-height;
$base-h3-line-height: $base-body-line-height;
$base-h4-line-height: $base-body-line-height;
$base-h5-line-height: $base-body-line-height;
$base-h6-line-height: $base-body-line-height;

// Globals
$container-max-width: 1152px;
$container-small-max-width: 832px;
$container-smallest-max-width: 739px;
$container-large-max-width: 1248px;

$container-padding-horizontal-m: 40px;
$container-padding-horizontal-s: 32px;
$container-padding-horizontal: 16px;

// Fonts
$global-font-family: $font-family-base;

// Links
$base-link-text-decoration: none;
$base-link-hover-text-decoration: none;

// Offcanvas
$offcanvas-bar-width: 360px;
$offcanvas-bar-padding-vertical: 0;
$offcanvas-bar-padding-vertical-m: 0;
$offcanvas-bar-padding-horizontal: 0;
$offcanvas-bar-padding-horizontal-m: 0;
$offcanvas-bar-background: white;
$offcanvas-z-index: 1001;

// Forms
$form-height: 58px;
$form-font-size: toRem(16px);
$form-line-height: 1.5;
$form-padding-horizontal: 14px; // -2px of border

$form-color: var(--form-default-color);
$form-background: var(--form-default-background);
$form-border-color: var(--form-default-border);

$form-hover-color: var(--form-hover-color);
$form-hover-background: var(--form-hover-background);
$form-hover-border-color: var(--form-hover-border);

$form-focus-color: var(--form-active-color);
$form-focus-background: var(--form-active-background);
$form-focus-border-color: var(--form-active-border);

$form-disabled-color: var(--form-disabled-color);
$form-disabled-background: var(--form-disabled-background);
$form-disabled-border-color: var(--form-disabled-border);

$form-placeholder-color: transparent;
$form-border-radius: 5px;

$form-radio-margin-top: 0;
$form-radio-background: rgba($color-green, 0.4);
$form-radio-checked-background: $form-radio-background;
$form-radio-checked-focus-background: $form-radio-background;
$form-checkbox-size: 20px;
$internal-form-checkbox-image: "data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.8132 7.12295C3.6222 7.12295 3.4292 7.04995 3.2832 6.90295L0.909203 4.52995C0.616203 4.23695 0.616203 3.76295 0.909203 3.46995C1.2022 3.17695 1.6762 3.17695 1.9692 3.46995L3.8132 5.31195L8.0292 1.09695C8.3222 0.803947 8.7962 0.803947 9.0892 1.09695C9.3822 1.38995 9.3822 1.86395 9.0892 2.15695L4.3432 6.90295C4.1972 7.04995 4.0052 7.12295 3.8132 7.12295Z' fill='%23009C5B'/%3E%3C/svg%3E";

// Modal
$modal-background: rgba(white, 0.6);
$modal-dialog-width: 448px;
$modal-dialog-background: $color-gray--lighter;
$modal-body-padding-vertical: 48px;
$modal-body-padding-horizontal: 48px;
$modal-close-position: 24px;
